import React, { useEffect, useState } from "react";
import BackButton from "../../constants/BackButton";
import { Images } from "../../constants/Images";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Container } from "react-bootstrap";
import Input from "../../constants/Input";
import SelectInput from "../../constants/SelectInput";
import PairButtons from "../../constants/PairButtons";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import SingleButton from "../../constants/SingleButton";
import { useGesture } from "@use-gesture/react";

const PlayerStatsReport = () => {
  // All options and states for fields
  const [batsman, setBatsman] = useState("");
  const [runs, setRuns] = useState("");
  const [playedShot, setPlayedShot] = useState("");
  const [areaToHit, setAreaToHit] = useState("");
  const [dismissals, setDismissals] = useState("");
  const [bowlerName, setBowlerName] = useState("");
  const [bowlingStyle, setBowlingStyle] = useState("");
  const [bowlingAction, setBowlingAction] = useState("");
  const [bowlLength, setBowlLength] = useState("");
  const [bowlArrival, setBowlArrival] = useState("");
  const [bowlVariation, setBowlVariation] = useState("");
  const [matchType, setMatchType] = useState("");
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);

  // ----------------------------------------------
  const [balls, setBalls] = useState([]);

  const bind = useGesture({
    onClick: ({ event }) => {
      const rect = event.target.getBoundingClientRect();
      const x = event.clientX - rect.left; // X coordinate relative to the div
      const y = event.clientY - rect.top; // Y coordinate relative to the div

      // Add the new ball to the list of balls to render
      setBalls((prevBalls) => [...prevBalls, { x, y }]);
    },
  });

  // ----------------------------------------------

  const batsmanOptions = [
    { id: 1, label: "RHB", value: "RHB" },
    { id: 2, label: "LHB", value: "LHB" },
  ];

  const runsOptions = [
    { id: 1, label: "0-50", value: "0-50" },
    { id: 2, label: "51-100", value: "51-100" },
    { id: 3, label: "100+", value: "100+" },
  ];

  const playedShotOptions = [
    { id: 1, label: "Cover Drive", value: "Cover Drive" },
    { id: 2, label: "Pull Shot", value: "Pull Shot" },
    { id: 3, label: "Straight Drive", value: "Straight Drive" },
  ];

  const areaToHitOptions = [
    { id: 1, label: "Offside", value: "Offside" },
    { id: 2, label: "Legside", value: "Legside" },
  ];

  const dismissalsOptions = [
    { id: 1, label: "Bowled", value: "Bowled" },
    { id: 2, label: "Caught", value: "Caught" },
    { id: 3, label: "LBW", value: "LBW" },
  ];

  const bowlerOptions = [
    { id: 1, label: "James Anderson", value: "James Anderson" },
    { id: 2, label: "Mitchell Starc", value: "Mitchell Starc" },
  ];

  const bowlingStyleOptions = [
    { id: 1, label: "Right Arm Fast", value: "Right Arm Fast" },
    { id: 2, label: "Left Arm Fast", value: "Left Arm Fast" },
  ];

  const bowlingActionOptions = [
    { id: 1, label: "Overarm", value: "Overarm" },
    { id: 2, label: "Sidearm", value: "Sidearm" },
  ];

  const bowlLengthOptions = [
    { id: 1, label: "Short", value: "Short" },
    { id: 2, label: "Good Length", value: "Good Length" },
    { id: 3, label: "Full", value: "Full" },
  ];

  const bowlArrivalOptions = [
    { id: 1, label: "Fast", value: "Fast" },
    { id: 2, label: "Slow", value: "Slow" },
  ];

  const bowlVariationOptions = [
    { id: 1, label: "Outswing", value: "Outswing" },
    { id: 2, label: "Inswing", value: "Inswing" },
  ];

  const matchTypeOptions = [
    { id: 1, label: "Test", value: "Test" },
    { id: 2, label: "ODI", value: "ODI" },
    { id: 3, label: "T20", value: "T20" },
  ];

  // Mapping over each field and options
  const fields = [
    {
      label: "Search Batsman",
      value: batsman,
      onChange: setBatsman,
      options: batsmanOptions,
    },
    {
      label: "Select Runs",
      value: runs,
      onChange: setRuns,
      options: runsOptions,
    },
    {
      label: "Select Played Shot",
      value: playedShot,
      onChange: setPlayedShot,
      options: playedShotOptions,
    },
    {
      label: "Select Area to Hit",
      value: areaToHit,
      onChange: setAreaToHit,
      options: areaToHitOptions,
    },
    {
      label: "Select Dismissal",
      value: dismissals,
      onChange: setDismissals,
      options: dismissalsOptions,
    },
    {
      label: "Search Bowler",
      value: bowlerName,
      onChange: setBowlerName,
      options: bowlerOptions,
    },
    {
      label: "Select Bowling Style",
      value: bowlingStyle,
      onChange: setBowlingStyle,
      options: bowlingStyleOptions,
    },
    {
      label: "Select Bowling Action",
      value: bowlingAction,
      onChange: setBowlingAction,
      options: bowlingActionOptions,
    },
    {
      label: "Select Bowl Length",
      value: bowlLength,
      onChange: setBowlLength,
      options: bowlLengthOptions,
    },
    {
      label: "Select Bowl Arrival",
      value: bowlArrival,
      onChange: setBowlArrival,
      options: bowlArrivalOptions,
    },
    {
      label: "Select Bowl Variation",
      value: bowlVariation,
      onChange: setBowlVariation,
      options: bowlVariationOptions,
    },
    {
      label: "Select Match Type",
      value: matchType,
      onChange: setMatchType,
      options: matchTypeOptions,
    },
  ];

  const columns1 = [
    {
      name: "Date",
      selector: (row) => moment(row.date).format("DD-MM-YYYY"),
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
    },
    {
      name: "Match No.",
      selector: (row) => row.matchNo,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
    },
    {
      name: "Team 1",
      selector: (row) => row.team1,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
    },
    {
      name: "Team 2",
      selector: (row) => row.team2,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
    },
    {
      name: "Batter",
      selector: (row) => row.batter,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
    },
    {
      name: "Bowler",
      selector: (row) => row.bowler,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
    },
    {
      name: "Over",
      selector: (row) => row.over,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
    },
    {
      name: "Run",
      selector: (row) => row.run,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
    },
    {
      name: "Claim",
      selector: (row) => row.claim,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
    },
    {
      name: "Out",
      selector: (row) => row.out,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
    },
    {
      name: "Video",
      cell: (row) => (
        <SingleButton
          content="PLAY"
          icon={Images.nextArrow}
          backgroundColor={"rgba(231, 50, 147, 1)"}
          onClick={() => {
            console.log(row);
          }}
        />
      ),
      style: { justifyContent: "center" },
    },
  ];

  const columns2 = [
    {
      name: "Format",
      selector: (row) => row.format,
      sortable: true,
      minWidth: "150px", // Wider column for Format
      style: {
        borderRight: "1px solid #ddd",
        justifyContent: "start",
        paddingLeft: "10px",
      },
    },
    {
      name: "Mat",
      selector: (row) => row.mat,
      sortable: true,
      style: {
        borderRight: "1px solid #ddd",
        justifyContent: "center",
        margin: 0,
      },
    },
    {
      name: "Inns",
      selector: (row) => row.inns,
      sortable: true,
      style: { borderRight: "1px solid #ddd", justifyContent: "center" },
    },
    {
      name: "NO",
      selector: (row) => row.no,
      sortable: true,
      style: { borderRight: "1px solid #ddd", justifyContent: "center" },
    },
    {
      name: "Runs",
      selector: (row) => row.runs,
      sortable: true,
      style: { borderRight: "1px solid #ddd", justifyContent: "center" },
    },
    {
      name: "HS",
      selector: (row) => row.hs,
      sortable: true,
      style: { borderRight: "1px solid #ddd", justifyContent: "center" },
    },
    {
      name: "Avg",
      selector: (row) => row.avg,
      sortable: true,
      style: { borderRight: "1px solid #ddd", justifyContent: "center" },
    },
    {
      name: "BF",
      selector: (row) => row.bf,
      sortable: true,
      style: { borderRight: "1px solid #ddd", justifyContent: "center" },
    },
    {
      name: "SR",
      selector: (row) => row.sr,
      sortable: true,
      style: { borderRight: "1px solid #ddd", justifyContent: "center" },
    },
    {
      name: "100s",
      selector: (row) => row.hundreds,
      sortable: true,
      style: { borderRight: "1px solid #ddd", justifyContent: "center" },
    },
    {
      name: "50s",
      selector: (row) => row.fifties,
      sortable: true,
      style: { borderRight: "1px solid #ddd", justifyContent: "center" },
    },
    {
      name: "4s",
      selector: (row) => row.fours,
      sortable: true,
      style: { borderRight: "1px solid #ddd", justifyContent: "center" },
    },
    {
      name: "6s",
      selector: (row) => row.sixes,
      sortable: true,
      style: { borderRight: "1px solid #ddd", justifyContent: "center" },
    },
    {
      name: "Ct",
      selector: (row) => row.catches,
      sortable: true,
      style: { borderRight: "1px solid #ddd", justifyContent: "center" },
    },
    {
      name: "St",
      selector: (row) => row.stumpings,
      sortable: true,
      style: { justifyContent: "center" },
    },
  ];

  useEffect(() => {
    // Populate dummy data
    const dummyData1 = [
      {
        id: 1,
        date: "2022-10-01",
        matchNo: "1",
        team1: "Pakistan",
        team2: "India",
        batter: "Babar Azam",
        bowler: "Jasprit Bumrah",
        over: "12.3",
        run: "4",
        claim: "Yes",
        out: "No",
      },
      {
        id: 2,
        date: "2022-10-02",
        matchNo: "2",
        team1: "Australia",
        team2: "England",
        batter: "David Warner",
        bowler: "Jofra Archer",
        over: "14.2",
        run: "6",
        claim: "No",
        out: "Yes",
      },
      // Add more dummy data as needed
    ];

    const dummyData2 = [
      {
        id: 1,
        format: "Test",
        mat: 10,
        inns: 20,
        no: 2,
        runs: 850,
        hs: 150,
        avg: 47.22,
        bf: 1800,
        sr: 47.2,
        hundreds: 2,
        fifties: 3,
        fours: 110,
        sixes: 12,
        catches: 10,
        stumpings: 0,
      },
      {
        id: 2,
        format: "ODI",
        mat: 15,
        inns: 14,
        no: 1,
        runs: 600,
        hs: 90,
        avg: 46.15,
        bf: 780,
        sr: 76.9,
        hundreds: 0,
        fifties: 4,
        fours: 65,
        sixes: 8,
        catches: 5,
        stumpings: 0,
      },
      {
        id: 3,
        format: "T20",
        mat: 25,
        inns: 24,
        no: 5,
        runs: 750,
        hs: 85,
        avg: 39.47,
        bf: 500,
        sr: 150.0,
        hundreds: 0,
        fifties: 7,
        fours: 80,
        sixes: 20,
        catches: 12,
        stumpings: 1,
      },
    ];
    setTableData1(dummyData1);
    setTableData2(dummyData2);
  }, []);

  return (
    <section
      id="playerStatsReport"
      style={{
        backgroundImage: `url(${Images.background2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="pt-5">
        <BackButton icon={Images.backArrow} content="Back" />
      </div>
      <LazyLoadImage
        src={Images.logo}
        width={100}
        style={{
          position: "absolute",
          left: "50%",
          top: "60px",
          transform: "translate(-50%, -50%)",
        }}
        alt="Logo"
      />
      <Container className="mt-5 playerStats-container">
        <Container className="px-4">
          <p className="pb-2 heading">Player Report:</p>{" "}
          <div className="d-flex align-items-center year-tournament-container">
            <span style={{ width: "200px", marginRight: "50px" }}>
              <Input
                formType="text"
                formPlaceholder="Year"
                icon={Images.searchIcon}
                size="md"
                iconReversed={true}
              />
            </span>
            <span style={{ width: "600px" }}>
              <Input
                formType="text"
                formPlaceholder="Select Tournament"
                icon={Images.searchIcon}
                size="md"
                iconReversed={true}
              />
            </span>
          </div>
          <section className="d-flex flex-wrap mb-4">
            {fields.map((field, index) => (
              <SelectInput
                key={index}
                label={field.label}
                value={field.value}
                onChange={field.onChange}
                options={field.options}
                ariaLabel={field.label}
              />
            ))}
          </section>
          <PairButtons
            content1="GENERATE"
            onClick1={() => console.log(-1)}
            icon1={Images.reportGenerate}
            backgroundColor1={"rgba(231, 50, 147, 1)"}
            color1={"#fff"}
            content2="RESET"
            onClick2={() => console.log("/create-tournament")}
            icon2={Images.reportReset}
            backgroundColor2={"#fff"}
            color2={"#000"}
            reversedIcon1={true}
          />
        </Container>
        <div className="table-container my-5">
          <p className="pb-2 heading">SELECTED PERFORMANCE</p>
          <DataTable
            columns={columns1}
            data={tableData1}
            // pagination
          />
        </div>
        <div className="table-container">
          <p className="pb-2 heading">BOWLING STYLE WISE PERFORMANCE</p>
          <DataTable
            columns={columns2}
            data={tableData2}
            // pagination
          />
        </div>
        <div className="table-container">
          <p className="pb-2 heading">GRAPHS</p>

          {/* Flex container for all the graphs */}
          <div
            style={{
              display: "flex", // Flexbox layout for same-row alignment
              justifyContent: "space-around", // Space between each graph
              alignItems: "center", // Vertically center items
            }}
          >
            {/* Pitch (with clickable balls) */}
            <div
              {...bind()}
              style={{
                width: "200px",
                height: "200px",
                border: "1px solid black",
                position: "relative",
                backgroundImage: `url(${Images.pitch})`,
                backgroundSize: "cover", // Ensure the image covers the div
                margin: "10px", // Add margin for spacing between graphs
              }}
            >
              {/* Render balls based on clicks on the pitch */}
              {balls.map((ball, index) => (
                <div
                  key={index}
                  style={{
                    position: "absolute",
                    width: "10px",
                    height: "10px",
                    backgroundColor: "white",
                    borderRadius: "50%", // Makes the div a circle (ball)
                    left: `${ball.x - 5}px`, // Center the ball horizontally
                    top: `${ball.y - 5}px`, // Center the ball vertically
                    pointerEvents: "none", // Disable events on the balls
                  }}
                />
              ))}
            </div>

            {/* Ball Hit Graph (optional, static for now) */}
            <div
              style={{
                width: "200px",
                height: "200px",
                backgroundImage: `url(${Images.ballHit})`,
                backgroundSize: "cover", // Ensure the image covers the div
                margin: "10px",
              }}
            ></div>

            {/* Stadium (with mirrored balls) */}
            <div
              style={{
                width: "200px",
                height: "200px",
                border: "1px solid black",
                position: "relative",
                backgroundImage: `url(${Images.stadium})`,
                backgroundSize: "cover", // Ensure the image covers the div
                margin: "10px", // Add margin for spacing between graphs
              }}
            >
              {/* Render the balls at the same coordinates on the stadium */}
              {balls.map((ball, index) => (
                <div
                  key={index}
                  style={{
                    position: "absolute",
                    width: "10px",
                    height: "10px",
                    backgroundColor: "white",
                    borderRadius: "50%", // Makes the div a circle (ball)
                    left: `${ball.x - 5}px`, // Center the ball horizontally
                    top: `${ball.y - 5}px`, // Center the ball vertically
                    pointerEvents: "none", // Disable events on the balls
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default PlayerStatsReport;
