import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Formik } from "formik";
import OTPInput, { ResendOTP } from "otp-input-react";
import Layout from "../../layouts/Layout";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../../constants/Images";
import { toast } from "react-toastify";
import { postRequestForm } from "../../helper/api";
import { setItemInLocalStorage } from "../../helper/helper";

const OTP = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOTP] = useState("");
  const OTPTime = 120;
  const [time, setTime] = useState(OTPTime);
  const [loading, setLoading] = useState(false);
  const [_id, setId] = useState("");
  const [route, setRoute] = useState("");

  // Resend OTP functionality
  const resendOTP = async () => {
    try {
      setLoading(true);
      const { result, error } = await postRequestForm(
        `/api/auth/resendOTP`,
        "",
        { _id }
      );

      if (result?.status === 200) {
        toast.success("OTP has been resent. Check your email.", {
          position: "top-center",
          autoClose: 5000,
        });
        setTime(OTPTime);
      } else {
        throw new Error(
          error?.response?.data?.message || "Failed to resend OTP."
        );
      }
    } catch (err) {
      toast.error(err.message || "An error occurred while resending OTP.", {
        position: "top-center",
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle OTP verification
  const verifyOTPHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { result, error } = await postRequestForm(
        `/api/auth/verifyOTP`,
        "",
        { _id, otp }
      );

      if (result?.status === 200) {
        const { user, token } = result?.data;

        // Store necessary details in local storage
        if (route !== "set-new-password") {
          setItemInLocalStorage("TOKEN", token);
          // setItemInLocalStorage("ROLE", user.role);
          setItemInLocalStorage("USER", JSON.stringify(user));
        }
        // Redirect based on user role
        if (route === "dashboard") {
          navigate("/dashboard");
        } else {
          navigate(`/${route}`, { state: { _id: user._id } });
        }
      } else {
        throw new Error(error?.response?.data?.message || "Invalid OTP.");
      }
    } catch (err) {
      toast.error(err.message || "OTP verification failed.", {
        position: "top-center",
      });
      setOTP("");
    } finally {
      setLoading(false);
    }
  };

  // Timer countdown effect
  useEffect(() => {
    if (time > 0) {
      const timer = setInterval(() => setTime(time - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [time]);

  // Populate `_id` and `route` from location state
  useEffect(() => {
    const state = location?.state;
    if (state?._id) setId(state._id);
    else navigate("/login"); // Redirect to login if no ID in state

    setRoute(state?.pathToGo || "dashboard");
  }, [location, navigate]);

  return (
    <Layout>
      <section
        id="otp"
        style={{
          backgroundImage: `url(${Images.background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={4} md={4} xs={12}>
              {/* Logo */}
              <div className="image-container text-center mb-5">
                <LazyLoadImage src={Images.logo} className="w-50" alt="Logo" />
              </div>

                  <form onSubmit={verifyOTPHandler}>
                    <div className="text-start">
                      <h1 className="newPassword">VERIFICATION</h1>
                      <p className="text mb-3">
                        Enter the 4-digit code sent to your email.
                      </p>
                    </div>

                    {/* OTP Input */}
                    <OTPInput
                      value={otp}
                      onChange={setOTP}
                      autoFocus
                      OTPLength={4}
                      otpType="number"
                      className="otp-container"
                    />

                    {/* Timer */}
                    {time > 0 ? (
                      <p className="timer text-center mt-3">
                        Time remaining: {Math.floor(time / 60)}:
                        {time % 60 < 10 ? "0" : ""}
                        {time % 60}
                      </p>
                    ) : (
                      <p className="text-center mt-3 text-danger">
                        OTP expired. Please resend.
                      </p>
                    )}

                    {/* Resend OTP */}
                    <ResendOTP
                      maxTime={OTPTime}
                      renderButton={(props) => (
                        <Button
                          {...props}
                          onClick={resendOTP}
                          disabled={time > 0}
                        >
                          Resend OTP
                        </Button>
                      )}
                      renderTime={(timeInSeconds) => (
                        <span>
                          {Math.floor(timeInSeconds / 60)}:{timeInSeconds % 60}
                        </span>
                      )}
                    />

                    <Button
                      type="submit"
                      className="btn-default w-100 mt-4"
                      disabled={loading || otp.length !== 4}
                    >
                      {loading ? "Verifying..." : "VERIFY"}
                    </Button>
                  </form>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default OTP;
