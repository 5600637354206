import React from 'react'
import { Col } from 'react-bootstrap';

const ExistingPlayerSkeleton = () => {
  return (
    <>
      <div className="skeleton-wrapper">
        <div className="skeleton skeleton-text" />
        <div className="skeleton skeleton-text" style={{ width: "30%" }} />
        <div className="skeleton skeleton-text" style={{ width: "60%" }} />
      </div>
      <Col lg={10} md={10} xs={10}>
        <div className="skeleton skeleton-text" style={{ width: "50%" }} />
        <div className="skeleton skeleton-text" />
        <div className="skeleton skeleton-text" />
        <div className="skeleton skeleton-text" />
      </Col>
      <Col lg={2} md={2} xs={2}>
        <div className="skeleton skeleton-image" />
      </Col>
    </>
  );
}

export default ExistingPlayerSkeleton
