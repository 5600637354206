import React, { useState, useEffect } from "react";
import { Form, Button, Container, Modal } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SingleButton from "./SingleButton";
import { Images } from "./Images";
import { uploadURL } from "../helper/api";
import { useNavigate } from "react-router-dom";

function TeamsModal(props) {
  const [teams, setTeams] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Modal Props:", props); // Log all props for debugging

    if (props?.teamDetails) {
      // Transform and set team details from props
      setTeams(props.teamDetails);
      console.log("teams", teams) 
    }
  }, [props]);

  // Function to count the number of selected teams
  const countSelectedTeams = () => {
    return teams.filter((team) => team.selected).length;
  };

  // Function to handle team selection
  const handleTeamSelect = (teamId) => {
    const selectedCount = countSelectedTeams();
    setTeams(
      teams.map((team) => {
        if (team.id === teamId) {
          // Allow deselecting any team
          if (team.selected) {
            return { ...team, selected: false };
          }
          // Allow selecting only if less than 2 teams are selected
          else if (selectedCount < 2) {
            return { ...team, selected: true };
          }
        }
        return team;
      })
    );
  };

  if (!props?.data) return null; // Return null if no data is provided

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="teams-modal"
      style={{ backgroundColor: "transparent" }}
    >
      <Container className="list-container">
        <h3 className="heading border-bottom-0">Team Selection</h3>
        <p>Select any two teams to start a match.</p>
        <div className="item-container mb-3">
          {teams &&
            teams.map((team) => (
              <div className="list-item" key={team.team._id}>
                <div className="item-content">
                  <Form.Check
                    type="checkbox"
                    id={`team-${team.team.id}`}
                    checked={team.team.selected}
                    onChange={() => handleTeamSelect(team.team.id)} // Handle team selection
                    disabled={!team.team.selected && countSelectedTeams() >= 2} // Disable checkbox if two teams are already selected
                  />
                  <LazyLoadImage
                    src={uploadURL + team.team.teamLogo}
                    className="flag"
                    height={50}
                    width={50}
                  />
                  <p className="team-name">{team.team.teamName}</p>
                </div>

                <div className="item-buttons">
                  <Button
                    className="btn-default me-3"
                    onClick={() => navigate(`/team?_id=${team.team._id}`)}
                  >
                    View
                  </Button>
                  <Button
                    className="btn-default"
                    onClick={() => navigate(`/team?_id=${team.team._id}`)}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            ))}
        </div>
        <SingleButton
          content="Start Match"
          icon={Images.tournamentType}
          backgroundColor="rgba(231, 50, 147, 1)"
          onClick={() => {
            console.log(
              "Selected Teams:",
              teams.filter((t) => t.selected)
            );
            props.onHide();
          }}
        />
      </Container>
    </Modal>
  );
}

export default TeamsModal;
