import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  Row,
  Modal,
  Form,
} from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Layout from "../../layouts/Layout";
import BackButton from "../../constants/BackButton";
import ReactImageUploading from "react-images-uploading";
import { Images } from "../../constants/Images";
import PairButtons from "../../constants/PairButtons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getRequest, postRequestForm, uploadURL } from "../../helper/api";
import { toast } from "react-toastify";

const CreateTeam = () => {
  const navigate = useNavigate();
  const { state, search } = useLocation();

  const [name, setName] = useState("");
  const [flags, setFlags] = useState([]);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [teamId, setTeamId] = useState("");

  // Image Upload Handling
  const onChangeImage = (imageList, addUpdateIndex) => {
    setFlags(imageList);
    if (imageList[addUpdateIndex]) {
      setFeaturedImage(imageList[addUpdateIndex].file);
    }
  };

  // Handle Team Submission
  const handleSubmit = async (e) => {
    // if (!teamName || !featuredImage || players.length === 0) {
    //   alert("Please fill in all fields and add at least one player.");
    //   return;
    // }

    // const teamData = {
    //   teamName,
    //   featuredImage,
    //   players: selectedPlayers, // Using the selected players' IDs
    // };

    // console.log("Team Data:", teamData);
    // alert("Team created successfully!");
    // navigate("/create-tournament");

    e.preventDefault();
    // if (!name || !flags) {
    //   toast.error("Please fill in all required fields.", {
    //     position: "top-center",
    //   });
    //   return;
    // }

    // console.log("formData", Object.fromEntries(formData));
    try {
      const endpoint = isEdit
        ? `/api/secure/team/edit-team`
        : `/api/secure/team/register-team`;
      const formData = new FormData();
      formData.append("teamName", name);
      if (selectedPlayers.length === 0) {
        toast.error("Please add at least one player.", {
          position: "top-center",
        });
      } else {
        // Append selectedPlayers as teamPlayers array
        selectedPlayers.forEach((player) => {
          formData.append("teamPlayers[]", player._id);
        });
      }
      if (featuredImage) {
        formData.append("teamLogo", featuredImage);
      } else {
        if (!isEdit) {
          toast.error("Please Upload the logo Image for a Team", {
            position: "top-center",
            theme: "colored",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          return false;
        }
      }

      if (isEdit) {
        formData.append("_id", teamId);
      }

      console.log("formData", Object.fromEntries(formData));
      const { result, error } = await postRequestForm(endpoint, "", formData);

      if (result?.status === 200 || result?.status === 201) {
        toast.success(`Team ${isEdit ? "updated" : "created"} successfully.`, {
          position: "top-center",
        });
        navigate("/team-selection");
      } else {
        throw new Error(error?.response?.data?.message || "Operation failed");
      }
    } catch (err) {
      toast.error(err.message, { position: "top-center" });
    }
  };

  // Check if editing a player
  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const id = queryParams.get("_id");
    if (id && !state) {
      setIsEdit(true);
      fetchTeamDetails(id);
    }
  }, [search, state]);
  // Check if editing a player
  useEffect(() => {
    console.log("state", state);
    if (state) {
      setName(state?.teamName !== null ? state?.teamName : "");
      setSelectedPlayers(state?.players !== null ? state?.players : []);
      if (state?.images !== null) {
        setFlags(state?.images !== null ? state?.images : []);
        setFeaturedImage(
          state?.images !== null ? state?.images[0]?.file : null
        );
      }
      if (state?.teamId !== null) {
        setIsEdit(true);
        setTeamId(state?.teamId);
      }
    }
  }, [state]);

  // Fetch Player Details for Editing
  const fetchTeamDetails = async (id) => {
    try {
      const { result, error } = await getRequest(
        `/api/secure/team/get-team?_id=${id}`
      );
      if (result?.status === 200) {
        const { team } = result.data;
        setTeamId(team?._id);
        setName(team?.teamName);
        if (
          team?.teamLogo !== undefined ||
          team?.teamLogo !== "" ||
          team?.teamLogo !== null
        ) {
          setFlags([
            {
              data_url: uploadURL + team?.teamLogo,
            },
          ]);
        }
        const modifiedPlayers = team?.teamPlayers?.map((player) => ({
          _id: player?._id,
          name: player?.playerName,
          image: player?.profileImage,
          selected: true,
        }));
        setSelectedPlayers(modifiedPlayers);
      } else {
        throw new Error(
          error?.response?.data?.message || "Failed to fetch player details"
        );
      }
    } catch (err) {
      toast.error(err.message, { position: "top-center" });
    }
  };
  return (
    <Layout>
      <section
        id="createTeam"
        style={{
          backgroundImage: `url(${Images.background2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <BackButton icon={Images.backArrow} content="Back" />
        <Container className="list-container w-75">
          <Row>
            <Col lg={10} md={10} xs={10}>
              <span className="pb-2 heading d-flex align-items-center">
                <p className="mb-0 me-3">Team Name:</p>
                <FormControl
                  type="text"
                  placeholder="Name"
                  className="w-50"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </span>
            </Col>

            <Col lg={2} md={2} xs={2}>
              <ReactImageUploading
                value={flags}
                onChange={onChangeImage}
                dataURLKey="data_url"
                maxNumber={1}
                maxFileSize={10000000}
                acceptType={["jpeg", "jpg", "gif", "png"]}
                resolutionType="less"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageUpdate,
                  isDragging,
                  dragProps,
                }) => (
                  <>
                    <div className="upload__image-wrapper mb-3">
                      {imageList?.length === 0 ? (
                        <div
                          className="image-item d-flex"
                          style={{
                            border: "1px solid #ccc",
                            cursor: "pointer",
                            width: "120px",
                            height: "144px",
                            justifyContent: "center",
                            alignItems: "center",
                            ...(isDragging ? { color: "red" } : {}),
                          }}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </div>
                      ) : (
                        imageList?.map((image, index) => (
                          <div key={index} className="image-item">
                            <LazyLoadImage
                              src={image["data_url"]}
                              width={65}
                              height={65}
                              alt="uploaded"
                            />
                            <Button
                              className="position-absolute"
                              variant="link"
                              onClick={() => onImageUpdate(index)}
                            >
                              <FontAwesomeIcon icon={faPencilAlt} />
                            </Button>
                          </div>
                        ))
                      )}
                    </div>
                  </>
                )}
              </ReactImageUploading>
            </Col>

            <Col lg={12} md={12} xs={12}>
              <div className="item-container mb-4">
                <>
                  {selectedPlayers &&
                    selectedPlayers.map((player) => (
                      <>
                        <div className="list-item" key={player._id}>
                          <div className="item-content">
                            <LazyLoadImage
                              src={uploadURL + player.image}
                              className="flag"
                              height={50}
                              width={50}
                            />
                            <p className="team-name">{player.name}</p>
                          </div>

                          <div className="item-buttons">
                            <Button className="btn-default me-3">View</Button>
                            <Button className="btn-default">Edit</Button>
                          </div>
                        </div>
                      </>
                    ))}
                </>
              </div>
            </Col>
          </Row>

          {/* Add Player Buttons */}
          <PairButtons
            content1="Add New Player"
            onClick1={() =>
              navigate("/player", {
                state: {
                  teamName: name,
                  images: flags,
                  selectedPlayers,
                  isEdit,
                  teamId,
                },
              })
            }
            icon1={Images.plus}
            backgroundColor1={"rgba(231, 50, 147, 1)"}
            color1={"#fff"}
            content2="Add Existing Player"
            onClick2={() =>
              navigate("/existing-player", {
                state: {
                  teamName: name,
                  images: flags,
                  selectedPlayers,
                  isEdit,
                  teamId,
                },
              })
            }
            icon2={Images.plus}
            backgroundColor2={"rgba(231, 50, 147, 1)"}
            color2={"#fff"}
          />
          <br />
          <PairButtons
            content1="Back"
            onClick1={() => navigate(-1)}
            icon1={Images.backArrowButton}
            backgroundColor1={"#fff"}
            color1={"#000"}
            content2="Done"
            onClick2={handleSubmit}
            icon2={Images.whiteNextArrow}
            backgroundColor2={"rgba(231, 50, 147, 1)"}
            color2={"#fff"}
            reversedIcon2={true}
          />
        </Container>
      </section>
    </Layout>
  );
};

export default CreateTeam;
