import ForgetPassword from "../views/auth/ForgetPassword";
import Login from "../views/auth/Login";
import OTP from "../views/auth/OTP";
import Dashboard from "../views/private/Dashboard";
import ProfileSettings from "../views/private/ProfileSettings";
import StartMatch from "../views/private/StartMatch";
import UserManagement from "../views/private/UserManagement";
import CreateTournament from "../views/private/CreateTournament";
import NewPassword from "../views/public/NewPassword";
import SiteSettings from "./SiteSettings";
import { useDynamicTitle, useDynamicViewport } from "./documentSettings";
import TeamSelection from "../views/private/TeamSelection";
import CreateTeam from "../views/private/CreateTeam";
import CreatePlayer from "../views/private/CreatePlayer";
import ExistingPlayer from "../views/private/ExistingPlayer";
import GetMatchReport from "../views/private/GetMatchReport";
import History from "../views/private/History";
import Previous from "../views/private/Previous";
import PlayerStatsReport from "../views/private/PlayerStatsReport";
import MatchScoring from "../views/private/MatchScoring";
import MatchSummary from "../views/private/MatchSummary";

function _login() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Login`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Login />;
}

function _forgetPassword() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Forget Password`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <ForgetPassword />;
}
function _otp() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | OTP`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <OTP />;
}

function _newPassword() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | New Password`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <NewPassword />;
}

function _dashboard() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Dashboard`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Dashboard />;
}

function _startMatch() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Start Match`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <StartMatch />;
}

function _createTournament() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Create Tournament`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <CreateTournament />;
}

function _profileSettings() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Profile Settings`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <ProfileSettings />;
}

function _userManagement() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | User Management`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <UserManagement />;
}

function _teamSelection() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Team Selection`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <TeamSelection />;
}

function _createTeam() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Create Team`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <CreateTeam />;
}

function _createPlayer() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Create Player`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <CreatePlayer />;
}

function _existingPlayer() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Existing Player`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <ExistingPlayer />;
}

function _getMatchReport() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Get Match Report`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <GetMatchReport />;
}

function _history() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | History`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <History />;
}

function _previous() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Previous Matches`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Previous />;
}

function _playerStatsReport() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Player Stats Report`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <PlayerStatsReport />;
}

function _matchScoring() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Match Scoring`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <MatchScoring />;
}

function _matchSummary() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Match Summary`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <MatchSummary />;
}

const routes = [
  {
    path: "/",
    name: "Home",
    icon: "",
    component: _dashboard,
    layout: "PRIVATE",
    show: "no",
  },
  {
    path: "/dashboard",
    name: "Home",
    icon: "",
    component: _dashboard,
    layout: "PRIVATE",
    show: "no",
  },
  {
    path: "/start-match",
    name: "Start Match",
    icon: "",
    component: _startMatch,
    layout: "PRIVATE",
    show: "no",
  },
  {
    path: "/create-tournament",
    name: "Create Tournament",
    icon: "",
    component: _createTournament,
    layout: "PRIVATE",
    show: "no",
  },
  {
    path: "/team",
    name: "Create Team",
    icon: "",
    component: _createTeam,
    layout: "PRIVATE",
    show: "no",
  },
  {
    path: "/player",
    name: "Create Player",
    icon: "",
    component: _createPlayer,
    layout: "PRIVATE",
    show: "no",
  },
  {
    path: "/existing-player",
    name: "Existing Player",
    icon: "",
    component: _existingPlayer,
    layout: "PRIVATE",
    show: "no",
  },
  {
    path: "/previous",
    name: "Previous",
    icon: "",
    component: _previous,
    layout: "PRIVATE",
    show: "no",
  },
  {
    path: "/player-stats-report",
    name: "Player Stats Report",
    icon: "",
    component: _playerStatsReport,
    layout: "PRIVATE",
    show: "no",
  },
  {
    path: "/get-match-report",
    name: "Get Match Report",
    icon: "",
    component: _getMatchReport,
    layout: "PRIVATE",
    show: "no",
  },
  {
    path: "/match-scoring",
    name: "Match Scoring",
    icon: "",
    component: _matchScoring,
    layout: "PRIVATE",
    show: "no",
  },
  {
    path: "/match-summary",
    name: "Match Summary",
    icon: "",
    component: _matchSummary,
    layout: "PRIVATE",
    show: "no",
  },
  {
    path: "/team-selection",
    name: "Team Selection",
    icon: "",
    component: _teamSelection,
    layout: "PRIVATE",
    show: "no",
  },
  {
    path: "/profile-settings",
    name: "Profile Settings",
    icon: "",
    component: _profileSettings,
    layout: "PRIVATE",
    show: "no",
  },
  {
    path: "/user-management",
    name: "User Management",
    icon: "",
    component: _userManagement,
    layout: "PRIVATE",
    show: "no",
  },
  {
    path: "/history",
    name: "History",
    icon: "",
    component: _history,
    layout: "PRIVATE",
    show: "no",
  },
  {
    path: "/login",
    name: "Login",
    icon: "",
    component: _login,
    layout: "",
    show: "no",
  },
  {
    path: "/forget-password",
    name: "Forget Password",
    icon: "",
    component: _forgetPassword,
    layout: "",
    show: "no",
  },
  {
    path: "/otp",
    name: "OTP",
    icon: "",
    component: _otp,
    layout: "",
    show: "no",
  },
  {
    path: "/set-new-password",
    name: "New Password",
    icon: "",
    component: _newPassword,
    layout: "",
    show: "no",
  },
];

export default routes;
