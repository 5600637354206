import React from "react";
import {
  ButtonGroup,
  Container,
  Dropdown,
  DropdownButton,
  Navbar,
  Row,
  Col,
} from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "./Images";
import { Link, useNavigate } from "react-router-dom";
import { removeItemFromLocalStorage } from "../helper/helper";

const NavComponent = ({ user }) => {
  const navigate = useNavigate();

  return (
    <Navbar className="bg-body-transparent navbar-component">
      <Container>
        <Navbar.Brand>Welcome, {user?.lastName}</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <div className="navbar-profile">
            <LazyLoadImage
              src={Images.profile}
              height={40}
              width={40}
              alt="User"
            />
            <DropdownButton
              as={ButtonGroup}
              id="dropdown-button-drop-end"
              drop="down"
              variant="transparent"
              className="dropdown-menu-end"
            >
              <Container>
                <Row className="my-3">
                  <Col lg={2} md={4} xs={4}>
                    <LazyLoadImage
                      src={Images.profile}
                      height={40}
                      width={40}
                      alt="User"
                    />
                  </Col>
                  <Col className="ms-2" lg={8} md={8} xs={8}>
                    {user?.firstName} <p className="mb-0">{user?.email}</p>
                  </Col>
                </Row>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="2">
                  <LazyLoadImage
                    src={Images.settings}
                    height={20}
                    width={20}
                    alt="Settings Icon"
                    className="me-2"
                  />
                  <Link to="/profile-settings">Profile Settings</Link>
                </Dropdown.Item>
                <Dropdown.Item eventKey="3">
                  <LazyLoadImage
                    src={Images.help}
                    height={20}
                    width={20}
                    alt="Help Icon"
                    className="me-2"
                  />
                  <Link to="/user-management">User Management</Link>
                </Dropdown.Item>
                <Dropdown.Item eventKey="4">
                  <LazyLoadImage
                    src={Images.upgrade}
                    height={20}
                    width={20}
                    alt="Upgrade Icon"
                    className="me-2"
                  />
                  <Link to="/new-password">Change Password</Link>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="5">
                  <LazyLoadImage
                    src={Images.signout}
                    height={20}
                    width={20}
                    alt="Signout Icon"
                    className="me-2"
                  />
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      removeItemFromLocalStorage("TOKEN");
                      removeItemFromLocalStorage("USER");

                      window.location.reload();
                    }}
                  >
                    Sign Out
                  </Link>
                </Dropdown.Item>
              </Container>
            </DropdownButton>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavComponent;
