import React, { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../../constants/Images";
import { Button, Col, Container, FormControl, Row } from "react-bootstrap";
import NavComponent from "../../constants/NavComponent";
import BackButton from "../../constants/BackButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus } from "@fortawesome/free-solid-svg-icons";
import SingleButton from "../../constants/SingleButton";
import { getRequest, postRequest, postRequestForm } from "../../helper/api";
import { toast } from "react-toastify";
import { getItemFromLocalStorage } from "../../helper/helper";
import ReactImageUploading from "react-images-uploading";

const ProfileSettings = () => {
  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    location: "",
    profileImage: null,
  });
  const [loading, setLoading] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [featuredImage, setFeaturedImage] = useState(null);

  // Fetch profile data
  const fetchProfile = async () => {
    try {
      const user = getItemFromLocalStorage("USER");
      const parseUser = JSON.parse(user);
      setProfile((prev) => ({
        ...prev,
        firstName: parseUser.firstName,
        lastName: parseUser.lastName,
        email: parseUser.email,
      }));
      const { result, error } = await getRequest(
        `/api/secure/user/get-profile?_id=${parseUser._id}`
      );
      if (result?.status === 200) {
        const userData = result.data.user;
        setProfile({
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          phone: userData.phone || "",
          location: userData.country || "",
          profileImage: userData.profileImage || Images.profile,
        });
      } else {
        throw new Error(
          error?.response?.data?.message || "Failed to fetch profile."
        );
      }
    } catch (err) {
      toast.error(err.message, {
        position: "top-center",
      });
    }
  };

  // Save profile changes
  const handleSaveChanges = async () => {
    try {
      setLoading(true);
      const user = getItemFromLocalStorage("USER");
      const parseUser = JSON.parse(user);

      const token = getItemFromLocalStorage("TOKEN");

      const formData = new FormData();
      formData.append("_id", parseUser._id);
      formData.append("firstName", profile.firstName);
      formData.append("lastName", profile.lastName);
      formData.append("email", profile.email);
      formData.append("phone", profile.phone);
      formData.append("country", profile.location);

      // Append profile image if selected
      if (featuredImage) {
        formData.append("profileImage", featuredImage);
      }

      const { result, error } = await postRequest(
        `/api/secure/user/edit-profile`,
        token,
        formData
      );
      if (result?.status === 200) {
        toast.success("Profile updated successfully!", {
          position: "top-center",
        });
        fetchProfile();
      } else {
        throw new Error(
          error?.response?.data?.message || "Failed to update profile."
        );
      }
    } catch (err) {
      toast.error(err.message, {
        position: "top-center",
      });
    } finally {
      setLoading(false);
    }
  };

  const onChangeImage = (imageList, addUpdateIndex) => {
    setImageList(imageList);
    if (imageList[addUpdateIndex]) {
      setFeaturedImage(imageList[addUpdateIndex].file);
    }
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <Layout>
      <section
        id="profileSettings"
        style={{
          backgroundImage: `url(${Images.background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <NavComponent brand={"Profile Settings"} />
        <BackButton icon={Images.backArrow} content="Back" />
        <Container className="logo-cards-container">
          <LazyLoadImage
            src={Images.logo}
            className="mt-3 mb-5"
            width={100}
            alt="logo"
          />
          <div className="profile-card">
            <Row>
              <Col lg={2} md={2} xs={2}>
                <ReactImageUploading
                  value={imageList}
                  onChange={onChangeImage}
                  dataURLKey="data_url"
                  maxNumber={1}
                  maxFileSize={10000000}
                  acceptType={["jpeg", "jpg", "gif", "png"]}
                >
                  {({ imageList, onImageUpload, onImageUpdate, dragProps }) => (
                    <div
                      className="upload__image-wrapper mb-3"
                      onClick={onImageUpload}
                      {...dragProps}
                      style={{
                        cursor: "pointer",
                        width: "120px",
                        height: "144px",
                        border: "1px solid #ccc",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {imageList.length === 0 ? (
                        <FontAwesomeIcon icon={faPlus} />
                      ) : (
                        <LazyLoadImage
                          key={0}
                          src={imageList[0]["data_url"]}
                          width={65}
                          height={65}
                          alt="Profile"
                        />
                      )}
                    </div>
                  )}
                </ReactImageUploading>
              </Col>
              <Col lg={10} md={10} xs={10}>
                <h3>{`${profile.firstName} ${profile.lastName}`}</h3>
                <p>{profile.email}</p>
              </Col>
            </Row>
            <Row className="text-input-container">
              <Col xs={4}>
                <p>First Name:</p>
              </Col>
              <Col xs={8}>
                <FormControl
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={profile.firstName}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="text-input-container">
              <Col xs={4}>
                <p>Last Name:</p>
              </Col>
              <Col xs={8}>
                <FormControl
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={profile.lastName}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="text-input-container">
              <Col xs={4}>
                <p>Email:</p>
              </Col>
              <Col xs={8}>
                <FormControl
                  type="text"
                  name="email"
                  placeholder="Your Email"
                  value={profile.email}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="text-input-container">
              <Col xs={4}>
                <p>Phone:</p>
              </Col>
              <Col xs={8}>
                <FormControl
                  type="text"
                  name="phone"
                  placeholder="Your Phone"
                  value={profile.phone}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="text-input-container border-0 mb-4">
              <Col xs={4}>
                <p>Location:</p>
              </Col>
              <Col xs={8}>
                <FormControl
                  type="text"
                  name="location"
                  placeholder="Your Location"
                  value={profile.location}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <SingleButton
              backgroundColor="rgba(231, 50, 147, 1)"
              content={loading ? "Saving..." : "Save Changes"}
              onClick={handleSaveChanges}
              disabled={loading}
            />
          </div>
        </Container>
      </section>
    </Layout>
  );
};

export default ProfileSettings;
