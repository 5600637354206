// localStorageUtils.js

import { encriptionKey } from "./api";
import CryptoJS from "crypto-js";

// Get an item from localStorage
export const getItemFromLocalStorage = (key) => {
  try {
    const item = localStorage.getItem(key);
    if (item === null) return null;

    // Decrypt the item (if it's encrypted)
    // Replace 'secretKey' with your actual secret key
    const decryptedItem = CryptoJS.AES.decrypt(item, encriptionKey).toString(
      CryptoJS.enc.Utf8
    );

    return decryptedItem;
  } catch (error) {
    console.error("Error getting item from localStorage:", error);
    return null;
  }
};

// Set an item in localStorage
export const setItemInLocalStorage = (key, value) => {
  try {
    // Encrypt the value (if needed)
    // Replace 'secretKey' with your actual secret key
    const encryptedValue = CryptoJS.AES.encrypt(
      value,
      encriptionKey
    ).toString();

    localStorage.setItem(key, encryptedValue);
  } catch (error) {
    console.error("Error setting item in localStorage:", error);
  }
};

// Remove an item from localStorage
export const removeItemFromLocalStorage = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error("Error removing item from localStorage:", error);
  }
};
