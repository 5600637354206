import React, { useState } from "react";
import Layout from "../../layouts/Layout";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../../constants/Images";
import {
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Navbar,
  Row,
} from "react-bootstrap";
import DashboardButton from "../../constants/DashboardButton";
import { useNavigate } from "react-router-dom";
import NavComponent from "../../constants/NavComponent";

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const handlePreviousClick = () => {
    navigate("/login");
  };

  const handleStartClick = () => {
    navigate("/start-match");
  };

  const DashboardPage = ({ user }) => {
    return (
      <section
        id="dashboard"
        style={{
          backgroundImage: `url(${Images.background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <NavComponent user={user} />

        <Container className="g-0">
          <Row className="justify-content-center buttons-container g-0">
            <Col className="g-0" lg={8} md={4} xs={12}>
              <div className="image-container text-center mb-5">
                <LazyLoadImage src={Images.logo} className="w-25" alt="Logo" />
              </div>

              <Row className="justify-content-center">
                <DashboardButton
                  icon={Images.previous}
                  content="PREVIOUS"
                  onClick={() => navigate("/previous")}
                />
                <DashboardButton
                  icon={Images.create}
                  content="CREATE NEW"
                  onClick={() => navigate("/create-tournament")}
                />
                <DashboardButton
                  icon={Images.stats}
                  content="STATS"
                  onClick={() => navigate("/player-stats-report")}
                />
                <DashboardButton
                  icon={Images.history}
                  content="REPORTS"
                  onClick={() => navigate("/history")}
                />
                <DashboardButton
                  icon={Images.start}
                  content="START MATCH"
                  onClick={() => navigate("/start-match")}
                />
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    );
  };
  return (
    <Layout>
      <DashboardPage user={user} />
    </Layout>
  );
};

export default Dashboard;
