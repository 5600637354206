import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Col, Container, Row } from "react-bootstrap";
import Layout from "../../layouts/Layout";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Input from "../../constants/Input";
import { Images } from "../../constants/Images";
import { postRequestForm } from "../../helper/api";
import { toast } from "react-toastify";

const NewPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [_id, setId] = useState("");
  const [loading, setLoading] = useState(false);

  // Validation Schema
  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters long"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  // Password Update Handler
  const resetPasswordHandler = async (values) => {
    try {
      setLoading(true);
      const { result, error } = await postRequestForm(
        `/api/auth/reset-password`,
        "",
        {
          _id,
          password: values.password,
        }
      );

      if (result?.status === 200) {
        toast.success("Password updated successfully.", {
          position: "top-center",
          autoClose: 2000,
        });
        navigate(`/login`);
      } else {
        throw new Error(
          error?.response?.data?.message || "Password reset failed."
        );
      }
    } catch (err) {
      toast.error(err.message || "An error occurred.", {
        position: "top-center",
      });
    } finally {
      setLoading(false);
    }
  };

  // Check for `_id` in location state
  useEffect(() => {
    if (location?.state?._id) {
      setId(location.state._id);
    } else {
      navigate("/login"); // Redirect to login if `_id` is missing
    }
  }, [location, navigate]);

  return (
    <Layout>
      <section
        id="newPassword"
        style={{
          backgroundImage: `url(${Images.background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={4} md={4} xs={12}>
              {/* Logo */}
              <div className="image-container text-center mb-5">
                <LazyLoadImage src={Images.logo} className="w-50" alt="Logo" />
              </div>

              {/* Password Reset Form */}
              <Formik
                initialValues={{ password: "", confirmPassword: "" }}
                validationSchema={resetPasswordSchema}
                onSubmit={resetPasswordHandler}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="text-start">
                      <h1 className="newPassword">NEW PASSWORD</h1>
                      <p className="text mb-3">
                        Set the new password for your account so you can login
                        and access all features.
                      </p>
                    </div>

                    {/* Password Input */}
                    <Input
                      formType="password"
                      formPlaceholder="NEW PASSWORD"
                      formHandleChange={handleChange}
                      formHandleBlur={handleBlur}
                      formName="password"
                      formValue={values.password}
                      icon={Images.lock}
                      size="lg"
                      iconColor="white"
                    />
                    {errors.password && touched.password && (
                      <div className="text-danger">{errors.password}</div>
                    )}

                    {/* Confirm Password Input */}
                    <Input
                      formType="password"
                      formPlaceholder="CONFIRM PASSWORD"
                      formHandleChange={handleChange}
                      formHandleBlur={handleBlur}
                      formName="confirmPassword"
                      formValue={values.confirmPassword}
                      icon={Images.lock}
                      size="lg"
                      iconColor="white"
                    />
                    {errors.confirmPassword && touched.confirmPassword && (
                      <div className="text-danger">
                        {errors.confirmPassword}
                      </div>
                    )}

                    {/* Submit Button */}
                    <Button
                      type="submit"
                      className="btn-default w-100 mt-4"
                      disabled={loading}
                    >
                      {loading ? "Updating..." : "UPDATE PASSWORD"}
                    </Button>
                  </form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default NewPassword;
