import ForgotPassword from "./views/auth/ForgetPassword";
import Login from "./views/auth/Login";
import NewPassword from "./views/public/NewPassword";
import OTP from "./views/auth/OTP";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import routes from "./helper/routes";
import { useEffect, useMemo, useState } from "react";
import { ToastContainer } from "react-toastify";
import {
  getItemFromLocalStorage,
  setItemInLocalStorage,
} from "./helper/helper";
import { getRequest } from "./helper/api";
import Preloader from "./constants/Preloader";

const getRoutes = (routes, user) => {
  return routes.map((prop, key) => {
    if (
      prop?.layout === "PRIVATE" &&
      user !== null &&
      (user?.permissions?.includes(prop?.name) ||
        user?.permissions?.includes("Admin"))
    ) {
      return (
        <Route
          exact
          key={key}
          path={`${prop.path}`}
          element={
            <LazyLoadComponent>
              <prop.component />
            </LazyLoadComponent>
          }
        />
      );
    } else {
      return (
        <Route
          exact
          path={`${prop.path}`}
          element={
            <LazyLoadComponent>
              <prop.component />
            </LazyLoadComponent>
          }
          key={key}
        />
      );
    }
  });
};
function App() {
  const [user, setUser] = useState(null); // Maintain the user state
  const [isLoading, setIsLoading] = useState(true); // Maintain the loading state

  const getUser = useMemo(() => {
    return async () => {
      try {
        const storedUser = JSON.parse(getItemFromLocalStorage("USER"));
        if (storedUser && storedUser?.username) {
          const { result, error } = await getRequest(
            `/api/secure/user/profile/${storedUser?.username}`
          );
          if (result?.status === 200 || result?.status === 201) {
            const { user } = result?.data;
            setUser(user);
            setItemInLocalStorage("USER", user);
          }
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error("Error while fetching user:", error);
        setUser(null);
      } finally {
        setIsLoading(false); // Ensure isLoading is set to false
      }
    };
  }, []);
  useEffect(() => {
    getUser();
  }, [getUser]);

  if (isLoading) {
    return <Preloader/>;
  } else {
    return (
      <Router>
        <Routes>
          {getRoutes(routes, user)}
          <Route path="*" element={<h1 className="text-center">Error 404 | Page Not Exist</h1>} />
        </Routes>
        <ToastContainer />
      </Router>
    );
  }
}

export default App;
