import React from "react";
import { Button, Col, Container, Navbar } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";

const BackButton = ({ icon, content, onClick }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <Navbar expand="lg" className=" pt-3 bg-body-transparent">
        <Container>
          <Button
            variant="primary"
            className="back-button d-flex justify-content-center align-items-center"
            style={{ padding: "8px 17px", fontSize: "10px" }}
            onClick={onClick ? onClick : () => navigate(-1)}
          >
            {icon && (
              <LazyLoadImage
                src={icon}
                alt="icon"
                width={10}
                className="me-2"
              />
            )}
            <span>{content}</span>
          </Button>
        </Container>
      </Navbar>
    </Container>
  );
};

export default BackButton;
