
import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
} from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "./Images";

const Preloader = () => {
  return (
    <section
      id="login"
      style={{
        backgroundImage: `url(${Images.background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container>
        <Row className=" justify-content-center">
          <Col lg={4} md={4} xs={12}>
            {/* Logo */}
            <div className="image-container text-center mb-5">
              <LazyLoadImage src={Images.logo} className="w-100" alt="Logo" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Preloader;
