import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  Row,
  Alert,
  Form,
} from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import Layout from "../../layouts/Layout";
import BackButton from "../../constants/BackButton";
import ReactImageUploading from "react-images-uploading";
import { Images } from "../../constants/Images";
import PairButtons from "../../constants/PairButtons";
import { useNavigate } from "react-router-dom";
const GetMatchReport = () => {
  const navigate = useNavigate();

  const [tournamentName, setTournamentName] = useState("WORLD CUP 2024");
  const [tournamentType, setTournamentType] = useState("T20");
  const [matchNumber, setMatchNumber] = useState("");
  const [venue, setVenue] = useState("");
  const [battingTeam, setBattingTeam] = useState("");
  const [bowlingTeam, setBowlingTeam] = useState("");
  const [toss, setToss] = useState("PAKISTAN");

  return (
    <Layout>
      <section
        id="createTeam"
        style={{
          backgroundImage: `url(${Images.background2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <BackButton icon={Images.backArrow} content="Back" />
        <div className="logo-container my-4">
          <LazyLoadImage src={Images.logo} width={120} alt="Logo" />
        </div>
        <Container className="list-container w-50 pb-4">
          <Row className="align-items-start">
            <Col lg={12} md={12} xs={12}>
              <span className="pb-3 heading d-flex align-items-center border-0">
                <p className="mb-0 me-3">TOURNAMENT NAME:</p>
                <p
                  className="mb-0 me-3"
                  style={{ color: "rgba(231, 50, 147, 1)" }}
                >
                  {tournamentName}
                </p>
              </span>
              <span className="pb-3 heading d-flex align-items-center border-0">
                <p className="mb-0 me-3">TOURNAMENT TYPE:</p>
                <p
                  className="mb-0 me-3"
                  style={{ color: "rgba(231, 50, 147, 1)" }}
                >
                  {tournamentType}
                </p>
              </span>
              <span className="pb-3 heading d-flex align-items-center border-0">
                <p className="mb-0 me-3">MATCH NUMBER:</p>
                <FormControl
                  type="text"
                  className="w-50"
                  value={matchNumber}
                  onChange={(e) => setMatchNumber(e.target.value)}
                />
              </span>
              <span className="pb-3 heading d-flex border-0">
                <p className="mb-0 me-3">VENUE:</p>
                <FormControl
                  type="text"
                  className="w-50"
                  value={venue}
                  onChange={(e) => setVenue(e.target.value)}
                />
              </span>
              <span className="pb-3 heading d-flex align-items-center border-0">
                <p className="mb-0 me-3">TOSS:</p>
                <p className="mb-0 me-3">PAKISTAN</p>
              </span>
              <span className="pb-3 heading d-flex border-0">
                <p className="mb-0 me-3">BATTING TEAM NAME:</p>
                <FormControl
                  type="text"
                  className="w-50"
                  value={battingTeam}
                  onChange={(e) => setBattingTeam(e.target.value)}
                />
              </span>
              <span className="pb-3 heading d-flex border-0">
                <p className="mb-0 me-3">BOWLING TEAM NAME:</p>
                <FormControl
                  type="text"
                  className="w-50"
                  value={bowlingTeam}
                  onChange={(e) => setBattingTeam(e.target.value)}
                />
              </span>
            </Col>
          </Row>
          <PairButtons
            content1="BACK"
            onClick1={() => navigate(-1)}
            icon1={Images.backArrowButton}
            backgroundColor1={"#fff"}
            color1={"#000"}
            content2="START"
            onClick2={() => navigate("/match-scoring")}
            icon2={Images.whiteNextArrow}
            backgroundColor2={"rgba(231, 50, 147, 1)"}
            color2={"#fff"}
            reversedIcon2={true}
          />
        </Container>
      </section>
    </Layout>
  );
};

export default GetMatchReport;
