import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
} from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Input = ({
    formType,
    formPlaceholder,
    formHandleChange,
    formHandleBlur,
    formName,
    formValue,
    icon,
    size,
    iconColor,
    iconReversed,
}) => {
  return (
    <InputGroup className="mb-3" size="lg">
      {icon && !iconReversed && (
        <InputGroup.Text id="inputGroup-sizing-lg">
          <LazyLoadImage src={icon} alt="icon" />
        </InputGroup.Text>
      )}
      <Form.Control
        type={formType}
        placeholder={formPlaceholder}
        onChange={formHandleChange}
        onBlur={formHandleBlur}
        name={formName}
        value={formValue}
      />
      {iconReversed && (
        <InputGroup.Text id="inputGroup-sizing-lg">
          <LazyLoadImage src={icon} alt="icon" />
        </InputGroup.Text>
      )}
    </InputGroup>
  );
}

export default Input