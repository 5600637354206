import React, { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../../constants/Images";
import {
  Button,
  Col,
  Container,
  Modal,
  Form,
  Row,
  Card,
} from "react-bootstrap";
import NavComponent from "../../constants/NavComponent";
import BackButton from "../../constants/BackButton";
import { toast } from "react-toastify";
import { getRequest, postRequest, postRequestForm } from "../../helper/api";
import { getItemFromLocalStorage } from "../../helper/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

const UserManagement = () => {
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  // Fetch all users
  const getAllUsers = async () => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/user/get-all`,
        token
      );
      if (result?.status === 200) {
        setUsers(result.data.users);
      } else {
        throw new Error(
          error?.response?.data?.message || "Failed to get users"
        );
      }
    } catch (err) {
      toast.error(err.message, {
        position: "top-center",
      });
    }
  };

  // Delete a user
  const handleDeleteUser = async (id) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await postRequest(
        `/api/secure/user/delete-user`,
        token,
        { userId: id }
      );
      if (result?.status === 200) {
        toast.success("User deleted successfully", {
          position: "top-center",
        });
        getAllUsers(); // Refresh user list
      } else {
        toast.error(error?.response?.data?.message || "Failed to delete user", {
          position: "top-center",
        });
      }
    } catch (err) {
      toast.error(err.message, {
        position: "top-center",
      });
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const UserManagementPage = ({ user }) => {
    return (<section
        id="userManagement"
        style={{
          backgroundImage: `url(${Images.background})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "100vh",
        }}
      >
        <NavComponent user={user} />
        <BackButton icon={Images.backArrow} content="Back" />
        <Container>
          <div className="logo-container">
            <LazyLoadImage
              src={Images.logo}
              className="mt-3 mb-5 logo"
              width={100}
              alt="logo"
            />
          </div>
          <div className="text-center mb-4">
            <Button variant="primary" onClick={handleShowModal}>
              <FontAwesomeIcon icon={faUserPlus} className="me-2" /> Create New
              User
            </Button>
          </div>
          <Row className="justify-content-center w-75 m-auto">
            {users.map((user) => (
              <Col
                key={user._id}
                lg={6}
                md={4}
                xs={12}
                className="mx-2 mb-3 user-card-container"
              >
                <Card>
                  <LazyLoadImage
                    src={Images.userIcon}
                    alt="icon"
                    width={90}
                    className="d-block mx-auto"
                  />
                  <Card.Body>
                    <Card.Title style={{ wordBreak: "break-all" }}>
                      {user.firstName} {user.lastName}
                    </Card.Title>
                    <Card.Text style={{ wordBreak: "break-all" }}>
                      {user.email}
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <div className="button-container">
                      <Button className="btn-apply">Edit</Button>
                      <Button
                        className="btn-editAccess"
                        onClick={() => handleDeleteUser(user._id)}
                      >
                        Delete
                      </Button>
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>

        <UserModal
          show={showModal}
          onHide={handleCloseModal}
          setUsers={setUsers}
        />
      </section>
    );
  };

  return (
    <Layout>
      <UserManagementPage user={user} />
    </Layout>
  );
};

const UserModal = React.memo(({ show, onHide, setUsers }) => {
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const { result, error } = await postRequestForm(
        "/api/auth/register",
        "",
        formValues
      );
      if (result?.status === 201 || result?.status === 200) {
        toast.success("User created successfully", {
          position: "top-center",
        });

        try {
          const token = getItemFromLocalStorage("TOKEN");
          const { result, error } = await getRequest(
            `/api/secure/user/get-all`,
            token
          );
          if (result?.status === 200) {
            setUsers(result.data.users);
          } else {
            throw new Error(
              error?.response?.data?.message || "Failed to get users"
            );
          }
        } catch (err) {
          toast.error(err.message, {
            position: "top-center",
          });
        }
        
        setFormValues({ firstName: "", lastName: "", email: "", password: "" });
        onHide();
      } else {
        throw new Error(
          error?.response?.data?.message || "Failed to create user"
        );
      }
    } catch (err) {
      toast.error(err.message, {
        position: "top-center",
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              value={formValues.firstName}
              onChange={handleInputChange}
              placeholder="Enter first name"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              value={formValues.lastName}
              onChange={handleInputChange}
              placeholder="Enter last name"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formValues.email}
              onChange={handleInputChange}
              placeholder="Enter email"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={formValues.password}
              onChange={handleInputChange}
              placeholder="Enter password"
              required
            />
          </Form.Group>
          <Button type="submit" variant="primary" className="w-100">
            Create User
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
});

export default UserManagement;
