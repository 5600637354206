import React, { useEffect, useState } from "react";
import { Images } from "../../constants/Images";
import BackButton from "../../constants/BackButton";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";

const MatchSummary = () => {
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);

  const columns1 = [
    {
      name: "Batting",
      selector: (row) => row.batting,
      sortable: true,
      minWidth: "300px", // Wider column for Format
      style: { borderRight: "1px solid #fff", justifyContent: "start" },
    },
    {
      name: "Runs",
      selector: (row) => row.runs,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
    },
    {
      name: "Balls",
      selector: (row) => row.balls,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
    },
    {
      name: "4s",
      selector: (row) => row.fours,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
    },
    {
      name: "6s",
      selector: (row) => row.sixes,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
    },
    {
      name: "S/R",
      selector: (row) => row.sr,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
    },
  ];

  const columns2 = [
    {
      name: "Bowling",
      selector: (row) => row.bowling,
      sortable: true,
      minWidth: "300px", // Wider column for Format
      style: {
        borderRight: "1px solid #ddd",
        justifyContent: "start",
        paddingLeft: "10px",
      },
    },
    {
      name: "Overs",
      selector: (row) => row.overs,
      sortable: true,
      style: {
        borderRight: "1px solid #ddd",
        justifyContent: "center",
        margin: 0,
      },
    },
    {
      name: "Runs",
      selector: (row) => row.runs,
      sortable: true,
      style: { borderRight: "1px solid #ddd", justifyContent: "center" },
    },
    {
      name: "W",
      selector: (row) => row.wickets,
      sortable: true,
      style: { borderRight: "1px solid #ddd", justifyContent: "center" },
    },
    {
      name: "M",
      selector: (row) => row.maiden,
      sortable: true,
      style: { borderRight: "1px solid #ddd", justifyContent: "center" },
    },
    {
      name: "ECON",
      selector: (row) => row.economy,
      sortable: true,
      style: { borderRight: "1px solid #ddd", justifyContent: "center" },
    },
  ];

  useEffect(() => {
    // Populate dummy data
    const dummyData1 = [
      {
        id: 1,
        batting: "Babar Azam",
        runs: 50,
        balls: 10,
        fours: 2,
        sixes: 1,
        sr: 50.0,
      },
      {
        id: 2,
        batting: "Virat Kohli",
        runs: 60,
        balls: 10,
        fours: 2,
        sixes: 1,
        sr: 60.0,
      },
      {
        id: 3,
        batting: "Rohit Sharma",
        runs: 70,
        balls: 10,
        fours: 2,
        sixes: 1,
        sr: 70.0,
      },
      {
        id: 4,
        batting: "Shikhar Dhawan",
        runs: 80,
        balls: 10,
        fours: 2,
        sixes: 1,
        sr: 80.0,
      },

      // Add more dummy data as needed
    ];

    const dummyData2 = [
      {
        id: 1,
        bowling: "Babar Azam",
        overs: 10,
        runs: 50,
        wickets: 1,
        maiden: 0,
        economy: 10.0,
      },
      {
        id: 2,
        bowling: "Virat Kohli",
        overs: 10,
        runs: 60,
        wickets: 1,
        maiden: 0,
        economy: 10.0,
      },
      {
        id: 3,
        bowling: "Rohit Sharma",
        overs: 10,
        runs: 70,
        wickets: 1,
        maiden: 0,
        economy: 10.0,
      },
      {
        id: 4,
        bowling: "Shikhar Dhawan",
        overs: 10,
        runs: 80,
        wickets: 1,
        maiden: 0,
        economy: 10.0,
      },
    ];
    setTableData1(dummyData1);
    setTableData2(dummyData2);
  }, []);
  return (
    <section
      id="matchSummary"
      style={{
        backgroundImage: `url(${Images.background2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        // backgroundColor: "rgb(7, 0, 148)",
      }}
    >
      <div className="">
        <BackButton icon={Images.backArrow} content="Back" />
      </div>
      <LazyLoadImage
        src={Images.logo}
        width={100}
        style={{
          position: "absolute",
          left: "50%",
          top: "60px",
          transform: "translate(-50%, -50%)",
        }}
        alt="Logo"
      />
      <Container>
        <h1 className="heading1 text-center">Match Analysis Summary</h1>
        <div className="m-auto mt-5 d-flex justify-content-evenly align-items-center">
          <div className="text-center">
            <LazyLoadImage
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/32/Flag_of_Pakistan.svg/960px-Flag_of_Pakistan.svg.png"
              alt="Logo"
              width={100}
              height={100}
              style={{ borderRadius: "100%" }}
            />
            <h2 className="heading2 text-center">PAKISTAN</h2>
          </div>
          <div>
            <h2 className="heading2 text-center mt-0">VS</h2>
          </div>
          <div className="text-center">
            <LazyLoadImage
              src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/960px-Flag_of_India.svg.png"
              alt="Logo"
              width={100}
              height={100}
              style={{ borderRadius: "50%" }}
            />
            <h2 className="heading2 text-center">INDIA</h2>
          </div>
        </div>
        <div className="m-auto mt-5 match-description-container">
          <span className="description-line d-flex align-items-center">
            <LazyLoadImage
              src={Images.summaryCalendar}
              alt="Logo"
              width={40}
              height={40}
              style={{ borderRadius: "100%" }}
            />
            <h3 className="heading3 ms-3">
              Match Date <p>Sat, 11 Nov 2024 10:00PM</p>
            </h3>
          </span>
          <span className="description-line d-flex align-items-center">
            <LazyLoadImage
              src={Images.summaryToss}
              alt="Logo"
              width={40}
              height={40}
              style={{ borderRadius: "100%" }}
            />
            <h3 className="heading3 ms-3">
              Toss <p>Pakistan won the toss and elected to bat first.</p>
            </h3>
          </span>
          <span className="description-line d-flex align-items-center">
            <LazyLoadImage
              src={Images.summaryLocation}
              alt="Logo"
              width={40}
              height={40}
              style={{ borderRadius: "100%" }}
            />
            <h3 className="heading3 ms-3">
              Venue <p>Gaddafi Stadium</p>
            </h3>
          </span>
        </div>
        <Row className="g-0 mt-5 ">
          <Col lg={6}>
            <div className="score-card p-4">
              <div
                className="d-flex justify-content-between align-items-center pb-3"
                style={{ borderBottom: "1px solid white" }}
              >
                <span className="d-flex align-items-center">
                  <LazyLoadImage
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/32/Flag_of_Pakistan.svg/960px-Flag_of_Pakistan.svg.png"
                    alt="Logo"
                    width={48}
                    height={48}
                    style={{ borderRadius: "100%" }}
                  />
                  <h2 className="heading2 d-flex align-items-baseline mt-0 ms-3">
                    112/7 <p className="m-0 fs-6 fw-light"> (15.4)</p>
                  </h2>
                </span>
                <p
                  className="m-0 fs-4"
                  style={{ color: "rgba(231, 50, 147, 1)" }}
                >
                  vs
                </p>
                <span className="d-flex align-items-center">
                  <LazyLoadImage
                    src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/960px-Flag_of_India.svg.png"
                    alt="Logo"
                    width={48}
                    height={48}
                    style={{ borderRadius: "100%" }}
                  />
                  <h2 className="heading2 d-flex align-items-baseline mt-0 ms-3">
                    286/6<p className="m-0 fs-6 fw-light">(50)</p>
                  </h2>
                </span>
              </div>
              <p
                className="m-0 fs-5 text-center mt-3"
                style={{ color: "rgba(231, 50, 147, 1)" }}
              >
                PAK won by 6 wickets
              </p>
            </div>
            <div className="score-card p-4 mt-3">
              <Row className="align-items-center">
                <Col lg={4}>
                  <h1 className="heading1 mt-0">Player of The Match</h1>
                </Col>
                <Col lg={4} className="text-center">
                  <LazyLoadImage
                    src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/960px-Flag_of_India.svg.png"
                    alt="Logo"
                    width={95}
                    height={95}
                    style={{ borderRadius: "100%" }}
                  />
                </Col>
                <Col lg={4}>
                  <h3 className="heading3 player-of-match">
                    Babar Azam:<p className="m-0 mt-2 fw-bold">Batting:</p>
                    <p className="m-0 fw-light">
                      80* (40b | 5x4s | 2x6s) SR: 200.00
                    </p>
                    <p className="m-0 mt-2 fw-bold">Bowling:</p>
                    <p className="m-0  fw-light">
                      4.0 overs (11R | 01W | 2x4s | 1x6s) ECON: 5.6
                    </p>
                  </h3>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={6}>
            <div className="m-auto ms-2 match-description-container px-5 py-2">
              <h1 className="heading2 my-3">Match Information</h1>
              <span className="description-line2 d-flex align-items-center">
                <LazyLoadImage
                  src={Images.summaryTournament}
                  alt="Logo"
                  width={40}
                  height={40}
                  style={{ borderRadius: "100%" }}
                />
                <h3 className="heading3 ms-3">
                  Tournament <p>ICC Cricket World Cup 2024</p>
                </h3>
              </span>
              <span className="description-line2 d-flex align-items-center">
                <LazyLoadImage
                  src={Images.summaryCalendar}
                  alt="Logo"
                  width={40}
                  height={40}
                  style={{ borderRadius: "100%" }}
                />
                <h3 className="heading3 ms-3">
                  Match Date <p>Sat, 11 Nov 2024 10:00PM</p>
                </h3>
              </span>
              <span className="description-line2 d-flex align-items-center">
                <LazyLoadImage
                  src={Images.summaryToss}
                  alt="Logo"
                  width={40}
                  height={40}
                  style={{ borderRadius: "100%" }}
                />
                <h3 className="heading3 ms-3">
                  Toss <p>Pakistan won the toss and elected to bat first.</p>
                </h3>
              </span>
              <span className="description-line2 d-flex align-items-center">
                <LazyLoadImage
                  src={Images.summaryLocation}
                  alt="Logo"
                  width={40}
                  height={40}
                  style={{ borderRadius: "100%" }}
                />
                <h3 className="heading3 ms-3">
                  Venue <p>Gaddafi Stadium</p>
                </h3>
              </span>
              <span className="description-line2 d-flex align-items-center">
                <LazyLoadImage
                  src={Images.summaryRefree}
                  alt="Logo"
                  width={40}
                  height={40}
                  style={{ borderRadius: "100%" }}
                />
                <h3 className="heading3 ms-3">
                  Match Refree <p>Aleem Dar</p>
                </h3>
              </span>
            </div>
          </Col>
        </Row>
        <h2 className="heading2 text-start mt-5 mb-3">India - 1st Innings</h2>
        <div className="table-container">
          <div
            className="d-flex justify-content-between pb-3 mb-3"
            style={{ borderBottom: "1px solid #fff" }}
          >
            <p className="heading2 mt-0">India - 1st Innings</p>
            <h2 className="heading2 d-flex align-items-baseline mt-0 ms-3">
              286/6<p className="m-0 fs-6 fw-light">(50)</p>
            </h2>
          </div>
          <DataTable
            columns={columns1}
            data={tableData1}
            // pagination
          />
          <div
            className="d-flex justify-content-between mt-2"
            style={{ color: "#fff" }}
          >
            <p className=" mt-0">Extras</p>
            <p className=" d-flex align-items-baseline mt-0">6 (1NB, 5WD)</p>
          </div>
          <p className=" m-0 fw-bold" style={{ color: "#fff" }}>
            Fall of Wickets
          </p>
          <p
            className=" d-flex align-items-baseline m-0 pb-2 mb-3"
            style={{ color: "#fff", borderBottom: "1px solid #fff" }}
          >
            1 - 25(Rohit Sharma), 1 - 25(Rohit Sharma), 1 - 25(Rohit Sharma), 1
            - 25(Rohit Sharma), 1 - 25(Rohit Sharma), 1 - 25(Rohit Sharma)
          </p>
          <DataTable
            columns={columns2}
            data={tableData2}
            // pagination
          />
        </div>
        <h2 className="heading2 text-start mt-5 mb-3">Pakistan - 1st Innings</h2>
        <div className="table-container">
          <div
            className="d-flex justify-content-between pb-3 mb-3"
            style={{ borderBottom: "1px solid #fff" }}
          >
            <p className="heading2 mt-0">Pakistan - 1st Innings</p>
            <h2 className="heading2 d-flex align-items-baseline mt-0 ms-3">
              286/6<p className="m-0 fs-6 fw-light">(50)</p>
            </h2>
          </div>
          <DataTable
            columns={columns1}
            data={tableData1}
            // pagination
          />
          <div
            className="d-flex justify-content-between mt-2"
            style={{ color: "#fff" }}
          >
            <p className=" mt-0">Extras</p>
            <p className=" d-flex align-items-baseline mt-0">6 (1NB, 5WD)</p>
          </div>
          <p className=" m-0 fw-bold" style={{ color: "#fff" }}>
            Fall of Wickets
          </p>
          <p
            className=" d-flex align-items-baseline m-0 pb-2 mb-3"
            style={{ color: "#fff", borderBottom: "1px solid #fff" }}
          >
            1 - 25(Rohit Sharma), 1 - 25(Rohit Sharma), 1 - 25(Rohit Sharma), 1
            - 25(Rohit Sharma), 1 - 25(Rohit Sharma), 1 - 25(Rohit Sharma)
          </p>
          <DataTable
            columns={columns2}
            data={tableData2}
            // pagination
          />
        </div>
      </Container>
    </section>
  );
};

export default MatchSummary;
