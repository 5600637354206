import React, { useState } from "react";
import { Form, Button, Container, Modal, Row, Col } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SingleButton from "./SingleButton";
import { Images } from "./Images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

function HistoryModal(props) {
  // State to store teams and their selection status
  const [teams, setTeams] = useState([
    {
      teamName1: "England",
      teamFlag1:
        "https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_England.svg/1200px-Flag_of_England.svg.png",
      id: 1,
      teamName2: "India",
      teamFlag2:
        "https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png",
      date: "2022-01-01",
      time: "10:00 PM",
    },
    {
      teamName1: "Pakistan",
      teamFlag1:
        "https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png",
      id: 2,
      teamName2: "Australia",
      teamFlag2:
        "https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png",
      date: "2022-01-01",
      time: "10:00 PM",
    },
    {
      teamName1: "Africa",
      teamFlag1:
        "https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png",
      id: 3,
      teamName2: "New Zealand",
      teamFlag2:
        "https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png",
      date: "2022-01-01",
      time: "10:00 PM",
    },
  ]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="teams-modal"
      style={{ backgroundColor: "transparent" }}
    >
      <Container className="list-container w-100">
        <h3 className="heading border-bottom-0">Tournament Matches:</h3>
        <p>
          Download the summary of any match by clicking the download button.
        </p>
        <div className="item-container">
          {teams.map((team) => (
            <Row key={team.id} className="align-items-center list-item">
              {/* Team 1 Icon and Name */}
              <Col xs={2} className="d-flex align-items-center">
                <LazyLoadImage
                  src={team.teamFlag1}
                  alt={team.teamName1}
                  width={30}
                  height={20}
                />
                <span className="ms-2 text-nowrap">
                  <p className="heading border-0">{team.teamName1}</p>
                </span>
              </Col>

              {/* VS Label */}
              <Col xs={1} className="text-center">
                <p className="heading border-0">VS</p>
              </Col>

              {/* Team 2 Icon and Name */}
              <Col xs={4} className="d-flex align-items-center">
                <LazyLoadImage
                  src={team.teamFlag2}
                  alt={team.teamName2}
                  width={30}
                  height={20}
                />
                <span className="ms-2">
                  <p className="heading border-0">{team.teamName2}</p>
                </span>
              </Col>

              {/* Match Date */}
              <Col xs={2} className="text-center">
                {team.date}
              </Col>

              {/* Match Time */}
              <Col xs={2} className="text-center">
                {team.time}
              </Col>

              {/* Download Button */}
              <Col xs={1} className="text-end item-buttons">
                <Button className="btn-default">
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
              </Col>
            </Row>
          ))}
        </div>
      </Container>
    </Modal>
  );
}

export default HistoryModal;
