import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';


import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.scss";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-date-picker/dist/DatePicker.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-datepicker/dist/react-datepicker.css";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
