import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../../constants/Images";
import OptionsButtons from "../../constants/OptionsButtons";
import DataTable from "react-data-table-component";
import OptionCard from "../../constants/OptionsCard";
import SingleButton from "../../constants/SingleButton";
import { useGesture } from "@use-gesture/react";

const MatchScoring = () => {
const [ballsCordinates, setBallsCordinates] = useState([]);

const bind = useGesture({
  onClick: ({ event }) => {
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left; // X coordinate relative to the div
    const y = event.clientY - rect.top; // Y coordinate relative to the div

    // Add the new ball to the list of balls to render
    setBallsCordinates((prevBalls) => [...prevBalls, { x, y }]);
  },
});


  const [tableData1, setTableData1] = useState([]);
  const balls = [
    {
      score: "6",
    },
    {
      score: "W",
    },
    {
      score: "0",
    },
    {
      score: "W",
    },
    {
      score: "2",
    },
    {
      score: "2",
    },
  ];
  const [score, setScore] = useState(null);
  const scoreOptions = [
    { label: "0", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
  ];

  const [wide, setWide] = useState(null);
  const wideOptions = [
    { label: "WD", value: "WD" },
    { label: "WD1", value: "WD1" },
    { label: "WD2", value: "WD2" },
    { label: "WD3", value: "WD3" },
    { label: "WD4", value: "WD4" },
    { label: "WD5", value: "WD5" },
    { label: "WD6", value: "WD6" },
  ];

  const [noBall, setNoBall] = useState(null);
  const noBallOptions = [
    { label: "NB", value: "NB" },
    { label: "NB1", value: "NB1" },
    { label: "NB2", value: "NB2" },
    { label: "NB3", value: "NB3" },
    { label: "NB4", value: "NB4" },
    { label: "NB5", value: "NB5" },
    { label: "NB6", value: "NB6" },
  ];

  const [byes, setByes] = useState(null);
  const byesOptions = [
    { label: "BY", value: "BY" },
    { label: "BY1", value: "BY1" },
    { label: "BY2", value: "BY2" },
    { label: "BY3", value: "BY3" },
    { label: "BY4", value: "BY4" },
    { label: "BY5", value: "BY5" },
    { label: "BY6", value: "BY6" },
  ];

  const [legByes, setLegByes] = useState(null);
  const legByesOptions = [
    { label: "LB", value: "LB" },
    { label: "LB1", value: "LB1" },
    { label: "LB2", value: "LB2" },
    { label: "LB3", value: "LB3" },
    { label: "LB4", value: "LB4" },
    { label: "LB5", value: "LB5" },
    { label: "LB6", value: "LB6" },
  ];

  const [out, setOut] = useState(null);
  const outOptions = [
    { label: "Caught", value: "Caught" },
    { label: "LBW", value: "LBW" },
    { label: "Bowled", value: "Bowled" },
    { label: "Run Out", value: "Run Out" },
    { label: "Stumped", value: "Stumped" },
    { label: "Timed Out", value: "Timed Out" },
    { label: "Retired Out", value: "Retired Out" },
  ];

  const [offShot, setOffShot] = useState(null);
  const offShotOptions = [
    { label: "Cover Drive", value: "Cover Drive" },
    { label: "Square Cut", value: "Square Cut" },
    { label: "Square Drive", value: "Square Drive" },
    { label: "Off Drive", value: "Off Drive" },
    { label: "Offside Punch", value: "Offside Punch" },
    { label: "Offside Push", value: "Offside Push" },
    { label: "Cross Cut", value: "Cross Cut" },
    { label: "Cross Drive", value: "Cross Drive" },
    { label: "Inside Drive", value: "Inside Drive" },
  ];

  const [feet, setFeet] = useState(null);
  const feetOptions = [
    { label: "No Feet Movement", value: "No Feet Movement" },
    { label: "Stood Deep in Crease", value: "Stood Deep in Crease" },
    { label: "Down the Track", value: "Down the Track" },
    { label: "Walking Out", value: "Walking Out" },
  ];

  const [longShot, setLongShot] = useState(null);
  const longShotOptions = [
    { label: "Pull", value: "Pull" },
    { label: "Sweep", value: "Sweep" },
    { label: "Hook", value: "Hook" },
    { label: "Flick", value: "Flick" },
    { label: "Leg-Glance", value: "Leg-Glance" },
    { label: "Slog-glance", value: "Slog-glance" },
    { label: "Paddle sweep", value: "Paddle sweep" },
  ];

  const [event, setEvent] = useState(null);
  const eventOptions = [
    { label: "Hit on Pad", value: "Hit on Pad" },
    { label: "Hit on Body", value: "Hit on Body" },
    { label: "Miss Time Shot", value: "Miss Time Shot" },
    { label: "Uncomfortable", value: "Uncomfortable" },
    { label: "Inside Edge", value: "Inside Edge" },
    { label: "Outside Edge", value: "Outside Edge" },
  ];

  const [length, setLength] = useState(null);
  const lengthOptions = [
    { label: "Short Length", value: "Short Length" },
    { label: "Full Toss", value: "Full Toss" },
    { label: "Good Length", value: "Good Length" },
    { label: "Half Volley", value: "Half Volley" },
    { label: "Yorker", value: "Yorker" },
    { label: "Short Good Length", value: "Short Good Length" },
  ];

  const [line, setLine] = useState(null);
  const lineOptions = [
    { label: "Stumps Line", value: "Stumps Line" },
    { label: "Outside Off", value: "Outside Off" },
    { label: "Wide Outside Off", value: "Wide Outside Off" },
    { label: "Down The Leg", value: "Down The Leg" },
  ];

  const [variation, setVariation] = useState(null);
  const variationOptions = [
    { label: "Slower ball", value: "Slower ball" },
    { label: "Bouncer", value: "Bouncer" },
    { label: "Googly", value: "Googly" },
    { label: "Doosra", value: "Doosra" },
    { label: "Off-Cutter", value: "Off-Cutter" },
    { label: "Leg-Cutter", value: "Leg-Cutter" },
    { label: "Knuckle Ball", value: "Knuckle Ball" },
  ];

  const [crease, setCrease] = useState(null);
  const creaseOptions = [
    { label: "Round the Wicket", value: "Round the Wicket" },
    { label: "Over the Wicket", value: "Over the Wicket" },
    { label: "Wide Crease", value: "Wide Crease" },
    { label: "Close to the Stumps", value: "Close to the Stumps" },
  ];

  const [pace, setPace] = useState(null);

  const columns1 = [
    {
      name: "Batter",
      selector: (row) => row.batter,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
    },
    {
      name: "Non-Striker",
      selector: (row) => row.nonStriker,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
    },
    {
      name: "Bowler",
      selector: (row) => row.bowler,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
    },
    {
      name: "Extras",
      selector: (row) => row.extras,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
    },
    {
      name: "Wickets",
      selector: (row) => row.wickets,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
    },
    {
      name: "Length",
      selector: (row) => row.length,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
    },
    {
      name: "Delivery",
      selector: (row) => row.delivery,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
    },
    {
      name: "Feet",
      selector: (row) => row.feet,
      sortable: true,
      style: { justifyContent: "center" },
    },
  ];

  useEffect(() => {
    // Populate dummy data
    const dummyData1 = [
      {
        id: 1,
        date: "2022-10-01",
        matchNo: "1",
        team1: "Pakistan",
        team2: "India",
        batter: "Babar Azam",
        bowler: "Jasprit Bumrah",
        over: "12.3",
        run: "4",
        claim: "Yes",
        out: "No",
      },
      {
        id: 2,
        date: "2022-10-02",
        matchNo: "2",
        team1: "Australia",
        team2: "England",
        batter: "David Warner",
        bowler: "Jofra Archer",
        over: "14.2",
        run: "6",
        claim: "No",
        out: "Yes",
      },
      // Add more dummy data as needed
    ];

    setTableData1(dummyData1);
  }, []);

  return (
    <section id="matchScoring">
      <Row className="g-0">
        <Col className="left-layout" lg={5}>
          <div className="match-details p-4">
            <div>
              <span>
                <LazyLoadImage
                  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png"
                  alt="flag"
                  width={24}
                />
                <p className="headingScoring">PAK</p>
              </span>
              <span>
                <LazyLoadImage src={Images.calendar} alt="flag" width={24} />
                <p className="headingScoring">May 9, 2014</p>
              </span>
              <span>
                <LazyLoadImage src={Images.location} alt="flag" width={24} />
                <p className="headingScoring">NBCA Stadium Karachi</p>
              </span>
            </div>
            <div className="vs">VS</div>
            <div className="right-align-container">
              <span>
                <LazyLoadImage
                  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png"
                  alt="flag"
                  width={24}
                  height={24}
                />
                <p className="headingScoring">IND</p>
              </span>
              <span>
                <LazyLoadImage src={Images.trophyIcon} alt="flag" width={24} />
                <p className="headingScoring">ICC Trophy</p>
              </span>
              <span>
                <LazyLoadImage src={Images.matchIcon} alt="flag" width={24} />
                <p className="headingScoring">Match 01</p>
              </span>
            </div>
          </div>
          <div className="p-4 match-details target-details">
            <div>
              <span>
                <LazyLoadImage
                  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png"
                  alt="flag"
                  style={{ width: "30px", height: "30px" }}
                />
                <p className="headingScoring d-flex align-items-center">
                  286/6<h6 className="m-0 ms-2 fw-light">(15.4)</h6>
                </p>
              </span>
              <span className="">
                <p className="headingScoring m-0 fw-light">
                  CRR: <strong className="fw-bold">6.59</strong>
                </p>
                <p className="headingScoring fw-light">
                  RRR: <strong className="fw-bold">7.1</strong>
                </p>
              </span>
            </div>
            <div className="vs">T20</div>
            <div className="right-align-container">
              <span>
                <LazyLoadImage
                  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png"
                  alt="flag"
                  style={{ width: "30px", height: "30px" }}
                />
                <p className="headingScoring d-flex align-items-center">
                  286/6<h6 className="m-0 ms-2 fw-light">(15.4)</h6>
                </p>
              </span>
              <span className="">
                <p className="headingScoring m-0 fw-light">
                  CRR: <strong className="fw-bold">6.59</strong>
                </p>
                <p className="headingScoring fw-light">
                  RRR: <strong className="fw-bold">7.1</strong>
                </p>
              </span>
            </div>
          </div>
          <div className="p-4 match-details current-scores">
            <div>
              <span>
                <LazyLoadImage src={Images.pinkBat} alt="flag" width={24} />
                <p className="headingScoring">
                  Baber Azam: 20 * (30)
                  <span className="fw-light mt-1 justify-content-start">
                    6s: 01 | 4s: 01 | Dots: 5 | Strike Rate: 80
                  </span>
                </p>
              </span>
              <span>
                <LazyLoadImage src={Images.pinkBat} alt="flag" width={24} />
                <p className="headingScoring">
                  M. Rizwan: 15 * (10)
                  <span className="fw-light mt-1 justify-content-start">
                    6s: 01 | 4s: 01 | Dots: 5 | Strike Rate: 80
                  </span>
                </p>
              </span>
            </div>
            <div className="right-align-container">
              <span>
                <LazyLoadImage
                  src={Images.pinkBall}
                  alt="flag"
                  width={34}
                  height={34}
                />
                <p className="headingScoring m-0">
                  Jasprit Bumrah <b className="fs-6 fw-light">(3.4)</b>
                  <span className="fw-light mt-1 justify-content-start">
                    Runs: 26 | Wkts: 02 | Maiden: 00 | Econ: 7.5
                  </span>
                </p>
              </span>
              <span className="d-flex flex-wrap">
                {balls.map((ball) => (
                  //if ball.score === W then background color of h is pink
                  <h5
                    className="overs"
                    style={{
                      backgroundColor:
                        ball.score === "W" ? "rgba(231, 50, 147, 1)" : "white",
                      color: ball.score === "W" ? "white" : "black",
                      marginLeft: "5px",
                      fontSize: "14px",
                    }}
                  >
                    {ball.score}
                  </h5>
                ))}
              </span>
            </div>
          </div>
          <div className="video"></div>
          <div className="scoring-buttons p-4">
            <OptionsButtons
              heading="SCORE"
              options={scoreOptions}
              selectedValue={score}
              setSelectedValue={setScore}
            />
            <OptionsButtons
              heading="WIDE"
              options={wideOptions}
              selectedValue={wide}
              setSelectedValue={setWide}
            />
            <OptionsButtons
              heading="NO-BALL"
              options={noBallOptions}
              selectedValue={noBall}
              setSelectedValue={setNoBall}
            />
            <OptionsButtons
              heading="BYES"
              options={byesOptions}
              selectedValue={byes}
              setSelectedValue={setByes}
            />
            <OptionsButtons
              heading="LEG BYES"
              options={legByesOptions}
              selectedValue={legByes}
              setSelectedValue={setLegByes}
            />
            <OptionsButtons
              heading="OUT"
              options={outOptions}
              selectedValue={out}
              setSelectedValue={setOut}
            />
          </div>
          <div className="table-container">
            <DataTable
              columns={columns1}
              data={tableData1}
              // pagination
            />
          </div>
        </Col>
        <Col className="right-layout" lg={7}>
          <p className="bigHeading text-center">BATTING</p>
          <div className="batting-cards">
            <OptionCard
              heading="FEET"
              options={feetOptions}
              selectedValue={feet}
              setSelectedValue={setFeet}
            />
            <OptionCard
              heading="OFF SHOTS"
              options={offShotOptions}
              selectedValue={offShot}
              setSelectedValue={setOffShot}
            />
            <OptionCard
              heading="LONG SHOTS"
              options={longShotOptions}
              selectedValue={longShot}
              setSelectedValue={setLongShot}
            />
            <OptionCard
              heading="EVENTS"
              options={eventOptions}
              selectedValue={event}
              setSelectedValue={setEvent}
            />
          </div>

          <p className="bigHeading text-center mt-4">BOWLING</p>
          <div className="batting-cards">
            <OptionCard
              heading="LENGTH"
              options={lengthOptions}
              selectedValue={length}
              setSelectedValue={setLength}
            />
            <OptionCard
              heading={"LINE"}
              options={lineOptions}
              selectedValue={line}
              setSelectedValue={setLine}
            />
            <OptionCard
              heading="VARIATION"
              options={variationOptions}
              selectedValue={variation}
              setSelectedValue={setVariation}
            />
            <div className="heading-options-line">
              <p className="headingScoring">PACE</p>
              <div className="">
                <Form.Control
                  type="float"
                  style={{
                    width: "80px",
                    height: "35px",
                    backgroundColor: "transparent",
                    color: "white",
                    marginBottom: "10px",
                  }}
                  placeholder="Custom Pace"
                />
                <SingleButton
                  content="Done"
                  backgroundColor="rgba(231, 50, 147, 1)"
                  styles="justify-content-center"
                />
              </div>
            </div>
            <OptionCard
              heading="CREASE"
              options={creaseOptions}
              selectedValue={crease}
              setSelectedValue={setCrease}
            />
          </div>
          <div className="graphs-container">

            {/* Flex container for all the graphs */}
            <div
              style={{
                display: "flex", // Flexbox layout for same-row alignment
                justifyContent: "space-around", // Space between each graph
                alignItems: "center", // Vertically center items
                
              }}
            >
              {/* Pitch (with clickable balls) */}
              <div
                {...bind()}
                style={{
                  width: "200px",
                  height: "200px",
                  border: "1px solid black",
                  position: "relative",
                  backgroundImage: `url(${Images.pitch})`,
                  backgroundSize: "cover", // Ensure the image covers the div
                  margin: "10px", // Add margin for spacing between graphs
                }}
              >
                {/* Render balls based on clicks on the pitch */}
                {ballsCordinates.map((ball, index) => (
                  <div
                    key={index}
                    style={{
                      position: "absolute",
                      width: "10px",
                      height: "10px",
                      backgroundColor: "white",
                      borderRadius: "50%", // Makes the div a circle (ball)
                      left: `${ball.x - 5}px`, // Center the ball horizontally
                      top: `${ball.y - 5}px`, // Center the ball vertically
                      pointerEvents: "none", // Disable events on the balls
                    }}
                  />
                ))}
              </div>

              {/* Ball Hit Graph (optional, static for now) */}
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundImage: `url(${Images.ballHit})`,
                  backgroundSize: "cover", // Ensure the image covers the div
                  margin: "10px",
                }}
              ></div>

              {/* Stadium (with mirrored balls) */}
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  border: "1px solid black",
                  position: "relative",
                  backgroundImage: `url(${Images.stadium})`,
                  backgroundSize: "cover", // Ensure the image covers the div
                  margin: "10px", // Add margin for spacing between graphs
                }}
              >
                {/* Render the balls at the same coordinates on the stadium */}
                {ballsCordinates.map((ball, index) => (
                  <div
                    key={index}
                    style={{
                      position: "absolute",
                      width: "10px",
                      height: "10px",
                      backgroundColor: "white",
                      borderRadius: "50%", // Makes the div a circle (ball)
                      left: `${ball.x - 5}px`, // Center the ball horizontally
                      top: `${ball.y - 5}px`, // Center the ball vertically
                      pointerEvents: "none", // Disable events on the balls
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default MatchScoring;
