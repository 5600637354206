import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../../constants/Images";
import Layout from "../../layouts/Layout";
import BackButton from "../../constants/BackButton";
import PairButtons from "../../constants/PairButtons";
import { getRequest, postRequest, postRequestForm, uploadURL } from "../../helper/api";
import { toast } from "react-toastify";

const TeamSelection = () => {
  const navigate = useNavigate();
  const { state: tournamentData } = useLocation();

  const [availableTeams, setAvailableTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);

  // Fetch teams from API
  const fetchTeams = async () => {
    try {
      const { result, error } = await getRequest(`/api/secure/team/get-teams`);
      if (result?.status === 200) {
        const teams = result.data.teams.map((team) => ({
          ...team,
          selected: false, // Add selected field for checkbox handling
        }));

        // Pre-select teams if editing an existing tournament
        if (tournamentData?.tournamentTeams) {
          const preselected = teams.map((team) => ({
            ...team,
            selected: tournamentData.tournamentTeams.includes(team._id),
          }));
          setAvailableTeams(preselected);
        } else {
          setAvailableTeams(teams);
        }
      } else {
        throw new Error(
          error?.response?.data?.message || "Failed to fetch teams"
        );
      }
    } catch (err) {
      toast.error(err.message, { position: "top-center" });
    }
  };

  // Handle checkbox change
  const toggleTeamSelection = (teamId) => {
    setAvailableTeams((prevTeams) =>
      prevTeams.map((team) =>
        team._id === teamId ? { ...team, selected: !team.selected } : team
      )
    );
  };

  // Handle Submit
   const handleSubmit = async () => {
     const selectedTeamIds = availableTeams
       .filter((team) => team.selected)
       .map((team) => team._id);

     if (selectedTeamIds.length === 0) {
       toast.error("Please select at least one team.", {
         position: "top-center",
       });
       return;
     }

     const payload = {
       tournamentName: tournamentData.tournamentName,
       tournamentType: tournamentData.tournamentType,
       startDate: tournamentData.startDate,
       endDate: tournamentData.endDate,
       tournamentTeams: selectedTeamIds, // Array of team IDs
     };

     try {
       const apiEndpoint = tournamentData._id
         ? `/api/secure/tournament/edit-tournament`
         : `/api/secure/tournament/register-tournament`;

       const { result, error } = await postRequestForm(apiEndpoint, "", payload);

       if (result?.status === 200 || result?.status === 201) {
         const message = tournamentData._id
           ? "Tournament updated successfully."
           : "Tournament registered successfully.";
         toast.success(message, { position: "top-center" });

         // Navigate to Tournament Overview or other appropriate page
         navigate("/tournament-overview", {
           state: {
             tournamentId: tournamentData._id || result.data.tournament._id,
           },
         });
       } else {
         throw new Error(
           error?.response?.data?.message || "Failed to save tournament"
         );
       }
     } catch (err) {
       toast.error(err.message, { position: "top-center" });
     }
   };

  useEffect(() => {
    fetchTeams();
    console.log("tournamentData", tournamentData);
  }, []);

  return (
    <Layout>
      <section
        id="teamSelection"
        style={{
          backgroundImage: `url(${Images.background2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <BackButton icon={Images.backArrow} content="Back" />
        <div className="logo-container my-5">
          <LazyLoadImage src={Images.logo} width={100} alt="logo" />
        </div>
        <Container className="list-container">
          <h3 className="pb-2 heading">Already Registered Teams</h3>
          <p>Select teams that will participate in this tournament.</p>
          <div className="item-container mb-4">
            {availableTeams.map((team) => (
              <div className="list-item" key={team._id}>
                <div className="item-content">
                  <Form.Check
                    type="checkbox"
                    id={team._id}
                    checked={team.selected}
                    onChange={() => toggleTeamSelection(team._id)}
                  />
                  <LazyLoadImage
                    src={uploadURL + team.teamLogo}
                    className="flag"
                    height={50}
                    width={50}
                  />
                  <p className="team-name">{team.teamName}</p>
                </div>

                <div className="item-buttons">
                  <Button
                    className="btn-default me-3"
                    onClick={() => navigate(`/team?_id=${team._id}`)}
                  >
                    View
                  </Button>
                  <Button
                    className="btn-default"
                    onClick={() => navigate(`/team?_id=${team._id}`)}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <PairButtons
            content1="Add New Team"
            onClick1={() => navigate("/team")}
            icon1={Images.plus}
            backgroundColor1={"rgba(231, 50, 147, 1)"}
            color1={"#fff"}
            content2="Next"
            onClick2={handleSubmit}
            icon2={Images.nextArrow}
            backgroundColor2={"#fff"}
            color2={"#000"}
            reversedIcon2={true}
          />
        </Container>
      </section>
    </Layout>
  );
};

export default TeamSelection;
