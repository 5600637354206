import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../../constants/Images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import Layout from "../../layouts/Layout";
import BackButton from "../../constants/BackButton";
import { getRequest } from "../../helper/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";

const CreateTournament = () => {
  const navigate = useNavigate();
  const [tournamentName, setTournamentName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tournamentType, setTournamentType] = useState("");

  const tournamentTypeOptions = [
    { value: "T20", label: "T20" },
    { value: "ODI", label: "ODI" },
    { value: "Test", label: "Test" },
    { value: "T10", label: "T10" },
  ];


  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !tournamentName ||
      !tournamentType ||
      !startDate ||
      !endDate 
    ) {
      toast.error("Please fill all fields and select teams.", {
        position: "top-center",
      });
      return;
    }

    const tournamentData = {
      tournamentName,
      tournamentType,
      startDate: startDate.toISOString(), // Convert Date object to string
      endDate: endDate.toISOString(),
    };


    navigate("/team-selection", { state: tournamentData });
  };


  return (
    <Layout>
      <section
        id="createTournament"
        style={{
          backgroundImage: `url(${Images.background2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <BackButton icon={Images.backArrow} content="Back" />

        <Container className="image-input-container">
          <div className="image-container text-center mt-4 mb-5">
            <LazyLoadImage src={Images.logo} width={100} alt="Logo" />
          </div>

          <Form onSubmit={handleSubmit}>
            {/* Tournament Name */}
            <InputGroup className="mb-3" size="lg">
              <InputGroup.Text>
                <LazyLoadImage src={Images.user} alt="icon" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Tournament Name"
                onChange={(e) => setTournamentName(e.target.value)}
                value={tournamentName}
                required
              />
            </InputGroup>

            {/* Tournament Type */}
            <InputGroup className="mb-3" size="lg">
              <InputGroup.Text>
                <LazyLoadImage src={Images.tournamentType} alt="icon" />
              </InputGroup.Text>
              <Form.Select
                aria-label="Tournament Type"
                onChange={(e) => setTournamentType(e.target.value)}
                value={tournamentType}
                required
              >
                <option value="" disabled>
                  Select Tournament Type
                </option>
                {tournamentTypeOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>

            {/* Start Date */}
            <InputGroup className="mb-3" size="lg">
              <InputGroup.Text>
                <LazyLoadImage src={Images.calendar} alt="icon" />
              </InputGroup.Text>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Select Start Date"
                className="form-control"
                dateFormat="dd-MM-yyyy"
              />
            </InputGroup>

            {/* End Date */}
            <InputGroup className="mb-3" size="lg">
              <InputGroup.Text>
                <LazyLoadImage src={Images.calendar} alt="icon" />
              </InputGroup.Text>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText="Select End Date"
                className="form-control"
                dateFormat="dd-MM-yyyy"
              />
            </InputGroup>

            {/* Start Button */}
            <Button
              variant="primary"
              type="submit"
              className="btn-default w-100 d-flex align-items-center justify-content-center"
            >
              START
              <FontAwesomeIcon className="ms-4" icon={faCircleChevronRight} />
            </Button>
          </Form>
        </Container>
      </section>
    </Layout>
  );
};

export default CreateTournament;
