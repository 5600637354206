import React from "react";
import { Form, InputGroup } from "react-bootstrap";

const SelectInput = ({ label, value, onChange, options, ariaLabel }) => (
  <span className="input-options" style={{ width: "fit-content" }}>
    <InputGroup className="mt-3 me-3" size="lg">
      <Form.Select
        aria-label={ariaLabel}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        className="input-default"
      >
        <option value="" disabled>
          {label}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </InputGroup>
  </span>
);

export default SelectInput;
