import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";

const SingleButton = ({
  content,
  backgroundColor,
  icon,
  onClick,
  color,
  styles,
  reversedIcon,
}) => {
  return (
    <div className={`button-group-pink-white ${styles}`}>
      <Button
        variant="primary"
        className="button-pink-white"
        style={{
          backgroundColor: backgroundColor,
          color: color,
        }}
        onClick={onClick}
      >
        {icon && !reversedIcon && (
          <LazyLoadImage src={icon} alt="icon" width={15} className="me-3" />
        )}
        <span style={{ textWrap: "nowrap" }}>{content}</span>
        {reversedIcon && (
          <LazyLoadImage
            src={icon}
            alt="icon"
            width={15}
            className="ms-3"
          />
        )}
      </Button>
    </div>
  );
};

export default SingleButton;
