import { faEyeSlash, faEye, faUser } from "@fortawesome/free-regular-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
} from "react-bootstrap";
import Input from "../../constants/Input";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { postRequestForm } from "../../helper/api";
import Layout from "../../layouts/Layout";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../../constants/Images";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const loginHandler = async (values) => {
    try {
      setLoading(true);
      const { result, error } = await postRequestForm(`/api/auth/login`, "", {
        email: values?.email?.toLowerCase(),
        password: values?.password,
      });
      if (result?.status === 200 || result?.status === 201) {
        const { user } = result?.data;
        navigate(`/otp`, { state: { _id: user._id, pathToGo: "dashboard" } });
        // if (user.role === "admin") {
        //   navigate(`/otp`, { state: { _id: user._id, pathToGo: "dashboard" } });
        // } else {
        //   navigate(`/otp`, { state: { _id: user._id, pathToGo: "account" } });
        // }
      } else if (error?.response?.status === 400) {
        setEmail(values.email);
        setPassword(values.password);
        toast.error(error?.response?.data?.message, {
          position: "top-center",
          theme: "colored",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      alert("Please enter correct email or password");
      console.log(`Error of login`, err.message);
    } finally {
      setLoading(false);
    }
  };


  return (
    <Layout>
      <section id="login" style={{
        backgroundImage: `url(${Images.background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}>
        <Container>
          <Row className=" justify-content-center">
            <Col lg={4} md={4} xs={12}>
              {/* Logo */}
              <div className="image-container text-center mb-5">
                <LazyLoadImage src={Images.logo} className="w-50" alt="Logo" />
              </div>

              <Formik
                initialValues={{
                  email: email,
                  password: password,
                }}
                validationSchema={LoginSchema}
                onSubmit={(values) => loginHandler(values)}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Input
                      formType="email"
                      formPlaceholder="EMAIL"
                      formHandleChange={handleChange}
                      formHandleBlur={handleBlur}
                      formName="email"
                      formValue={values.email}
                      icon={Images.user}
                      size="md"
                      iconColor="white"
                    />

                    <Input
                      formType={"password"}
                      formPlaceholder="PASSWORD"
                      formHandleChange={handleChange}
                      formHandleBlur={handleBlur}
                      formName="password"
                      formValue={values.password}
                      icon={Images.lock}
                      size="md"
                      iconColor="white"
                    />

                    <Button
                      variant="primary"
                      type="submit"
                      className="btn-default w-100 mt-5"
                      disabled={loading}
                    >
                      LOGIN
                    </Button>

                    {/* Forget Password Link */}
                    <div className="mt-2 text-end pe-3">
                      <Link
                        to="/forget-password"
                        className="forgot-password-link"
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default Login;
