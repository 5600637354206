import React, { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import { Button, Container } from "react-bootstrap";
import BackButton from "../../constants/BackButton";
import { Images } from "../../constants/Images";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronDown } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import PairButtons from "../../constants/PairButtons";
import TeamsModal from "../../constants/TeamsModal";
import HistoryModal from "../../constants/HistoryModal";

const History = () => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null); // Store selected row data
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(4);
  const [totalTournaments, setTotalTournaments] = useState(0);

  // Dummy Data
  var dummyData = [
    {
      id: 1,
      name: "Tournament 1",
      year: "2016",
      teams: [
        {
          id: 1,
          name: "Pakistan",
          code: "PK",
          players: [
            {
              id: 1,
              name: "Babar Azam",
              battingStyle: "RHB",
              wicketKeeper: false,
              bowlingStyle: "Right-arm fast-medium",
              profile:
                "https://upload.wikimedia.org/wikipedia/commons/4/4a/Babar_Azam_2019.jpg",
            },
            {
              id: 2,
              name: "Shadab Khan",
              battingStyle: "RHB",
              wicketKeeper: false,
              bowlingStyle: "Right-arm fast-medium",
              profile:
                "https://upload.wikimedia.org/wikipedia/commons/4/4a/Babar_Azam_2019.jpg",
            },
          ],
        },
        "India",
        "Afganistan",
      ],
      startDate: "2022-01-01T00:00:00.000Z",
      endDate: "2022-01-01T00:00:00.000Z",
    },
    {
      id: 2,
      name: "Tournament 2",
      year: "2017",
      teams: ["England", "Australia", "Canada"],
      startDate: "2022-01-01T00:00:00.000Z",
      endDate: "2022-01-01T00:00:00.000Z",
    },
    {
      id: 3,
      name: "Tournament 3",
      year: "2018",
      teams: ["USA", "China", "Japan"],
      startDate: "2022-01-01T00:00:00.000Z",
      endDate: "2022-01-01T00:00:00.000Z",
    },
  ];

  const columns = [
    {
      name: "Year",
      selector: (row) => row.year,
      sortable: true,
      maxWidth: "90px",
      style: {
        color: "white",
        borderRight: "1px solid white",
        justifyContent: "center",
      },
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      minWidth: "250px",
      cell: (row) => (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span style={{ marginRight: "8px", color: "white" }}>{row.name}</span>
          <FontAwesomeIcon
            icon={faCircleChevronDown}
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => {
              setSelectedRowData(row); // Set selected row data
              setModalShow(true); // Show modal
            }}
          />
        </div>
      ),
      style: {
        color: "white",
        borderRight: "1px solid white",
      },
    },
    {
      name: "Start Date",
      selector: (row) => moment(row.startDate).format("DD-MM-YYYY"),
      sortable: true,
      maxWidth: "130px",
      style: {
        color: "white",
        borderRight: "1px solid white",
        justifyContent: "center",
      },
    },
    {
      name: "End Date",
      selector: (row) => moment(row.endDate).format("DD-MM-YYYY"),
      sortable: true,
      maxWidth: "120px",
      style: { color: "white", justifyContent: "center" },
    },
  ];

  const getTournaments = () => {
    setTableData(dummyData);
    setTotalTournaments(dummyData.length);
  };

  useEffect(() => {
    getTournaments();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  return (
    <Layout>
      <section
        id="startMatch"
        style={{
          backgroundImage: `url(${Images.background2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <BackButton icon={Images.backArrow} content="Back" />
        <Container className="logo-table-container mt-5">
          <LazyLoadImage
            className="logo mt-3 mb-5"
            src={Images.logo}
            width={100}
            alt="Logo"
          />
          <div className="table-container">
            <p className="pb-2 heading">History</p>
            <DataTable
              columns={columns}
              data={tableData.slice(
                (currentPage - 1) * perPage,
                currentPage * perPage
              )}
              pagination
              paginationServer
              paginationTotalRows={totalTournaments}
              paginationPerPage={perPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </div>
        </Container>
      </section>

      {/* Modal for showing selected team data */}
      <HistoryModal
        show={modalShow}
        data={selectedRowData} // Pass the selected row data
        onHide={() => setModalShow(false)}
      />
    </Layout>
  );
};

export default History;
