import React from "react";
import { Button, Col } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

const DashboardButton = ({ icon, content, onClick }) => {
  return (
    <Col lg={6} md={4} xs={12} className="mb-3">
      <Button
        variant="primary"
        className="btn-default w-100 d-flex justify-content-center align-items-center"
        onClick={onClick} // Button triggers the function passed from Dashboard
      >
        <LazyLoadImage src={icon} alt="icon" className="me-4" />
        <span>{content}</span>
      </Button>
    </Col>
  );
};

export default DashboardButton;
