import { Images } from "../constants/Images";

const SiteSettings = {
  __SITE_ID: "007",
  SITE_TITLE: "Talking Bat",
  SITE_LOGO: Images.logo,
  Site_LINK: "/",
  Copyright_text: `©${new Date().getFullYear()} Talking Bat, ALL RIGHTS RESERVED.`,
};

export default SiteSettings;
