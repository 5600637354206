import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Layout from "../../layouts/Layout";
import BackButton from "../../constants/BackButton";
import PairButtons from "../../constants/PairButtons";
import { useLocation, useNavigate } from "react-router-dom";
import { Images } from "../../constants/Images";
import ExistingPlayerSkeleton from "../../constants/ExistingPlayerSkeleton";
import { getRequest, uploadURL } from "../../helper/api";
import { toast } from "react-toastify";

const ExistingPlayer = () => {
  const navigate = useNavigate();

  const { state } = useLocation();

  const [loading, setLoading] = useState(true); // Loading state to simulate loading effect
  const [existingPlayers, setExistingPlayers] = useState([]);

  const [playerName, setPlayerName] = useState("");

  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [teamName, setTeamName] = useState("");
  const [images, setImages] = useState("");

  const fetchExitingPlayers = async () => {
    try {
      const { result, error } = await getRequest(
        `/api/secure/player/get-players`
      );
      if (result?.status === 200) {
        setExistingPlayers(result.data.players);
        setLoading(false);
      } else {
        throw new Error(
          error?.response?.data?.message || "Failed to fetch existing players"
        );
      }
    } catch (err) {
      console.error("Error fetching existing players:", err);
    }
  };

  const [players, setPlayers] = useState([]);
  const handleCheckboxChange = (player) => {
    setPlayers((prev) => {
      const isSelected = prev?.some((p) => p._id === player._id);
      if (isSelected) {
        // Remove player if already selected
        return prev?.filter((p) => p._id !== player._id);
      } else {
        // Add player if not already selected
        if (prev?.length > 0) {
          return [
            ...prev,
            {
              _id: player._id,
              selected: true,
              name: player.playerName,
              image: player.profileImage,
            },
          ];
        } else {
          return [
            {
              _id: player._id,
              selected: true,
              name: player.playerName,
              image: player.profileImage,
            },
          ];
        }
      }
    });
  };
  useEffect(() => {
    fetchExitingPlayers();
  }, []);
  useEffect(() => {
    if (state) {
      setTeamName(state?.teamName);
      setImages(state?.images);
      setSelectedPlayers(state?.selectedPlayers);
    }
  }, [state]);

  useEffect(() => {
    setPlayers(selectedPlayers !== null ? selectedPlayers : []);
  }, [selectedPlayers]);

  return (
    <Layout>
      <section
        id="teamSelection"
        style={{
          backgroundImage: `url(${Images.background2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <BackButton icon={Images.backArrow} content="Back" />
        <div className="logo-container mt-3">
          <LazyLoadImage src={Images.logo} width={100} alt="logo" />
        </div>
        <Container className="list-container">
          <div className="pb-3 heading d-flex align-items-center border-0">
            <h3 className="mb-0 me-3 heading">SEARCH EXISTING PLAYER</h3>
            <FormControl
              type="text"
              placeholder="Name"
              className="w-50"
              value={playerName}
              onChange={(e) => setPlayerName(e.target.value)}
            />
          </div>
          <div className="item-container mb-4">
            {loading ? (
              <ExistingPlayerSkeleton />
            ) : (
              <>
                {existingPlayers.map((player) => (
                  <>
                    <div className="list-item" key={player._id}>
                      <div className="item-content">
                        <Form.Check
                          type="checkbox"
                          id={`checkbox-${player._id}`}
                          checked={players?.some((p) => p._id === player._id)}
                          onChange={() => handleCheckboxChange(player)}
                        />
                        <LazyLoadImage
                          src={uploadURL + player.profileImage}
                          className="flag"
                          height={50}
                          width={50}
                        />
                        <p className="team-name">{player.playerName}</p>
                      </div>

                      <div className="item-buttons">
                        <Button className="btn-default me-3">View</Button>
                        <Button className="btn-default">Edit</Button>
                      </div>
                    </div>
                  </>
                ))}
              </>
            )}
          </div>
          <PairButtons
            content1="CANCEL"
            onClick1={() => {
              if (state?.isEdit) {
                navigate(`/team?_id=${state.teamId}`, {
                  state: {
                    players,
                    teamName,
                    images,
                    isEdit: state?.isEdit,
                    teamId: state.teamId,
                  },
                });
              } else {
                navigate("/team", {
                  state: {
                    players,
                    teamName,
                    images,
                    isEdit: false,
                    teamId: null,
                  },
                });
              }
            }}
            icon1={Images.backArrowButton}
            backgroundColor1={"#fff"}
            color1={"#000"}
            content2="DONE"
            onClick2={() => {
              if (players.length === 0) {
                toast.error("Please select at least one player", {
                  position: "top-center",
                  theme: "colored",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                });
                return;
              }
              console.log(players, teamName, images);

              if (state?.isEdit) {
                navigate(`/team?_id=${state.teamId}`, {
                  state: {
                    players,
                    teamName,
                    images,
                    isEdit: state?.isEdit,
                    teamId: state.teamId,
                  },
                });
              } else {
                navigate("/team", {
                  state: {
                    players,
                    teamName,
                    images,
                    isEdit: false,
                    teamId: null,
                  },
                });
              }
            }}
            icon2={Images.whiteNextArrow}
            backgroundColor2={"rgba(231, 50, 147, 1)"}
            color2={"#fff"}
            reversedIcon2={true}
          />
        </Container>
      </section>
    </Layout>
  );
};

export default ExistingPlayer;
