import React, { useState } from "react";

const OptionCard = ({
  heading,
  options,
  selectedValue,
  setSelectedValue,
}) => {
  return (
    <div className="heading-options-line">
      <p className="headingScoring">{heading}</p>
      <div className="btns-options">
        {options.map((option) => (
          <button
            key={option.value}
            style={{
              backgroundColor:
                option.value === selectedValue
                  ? "rgba(231, 50, 147, 1)" // Highlight selected button
                  : "rgba(98, 97, 141, 1)", // Default background for other buttons
              color: "white",
              width: "120px",
              height: "35px",
              border: "none",
              borderRadius: "5px",
              fontWeight: "600",
              marginBottom: "5px",
              display: "flex", // Use flexbox for centering text
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
              textAlign: "center", // Align text in center
              whiteSpace: "nowrap", // Prevent text wrapping
              overflow: "hidden", // Hide overflow
              textOverflow: "ellipsis", // Add ellipsis if text overflows
              fontSize: "clamp(0.5rem, 1vw, 0.8rem)", // Responsive font size
            }}
            onClick={() => {
              // Toggle the selection
              if (option.value === selectedValue) {
                setSelectedValue(null); // Deselect if already selected
              } else {
                setSelectedValue(option.value); // Set the selected value
              }
            }}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default OptionCard;
