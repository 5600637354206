import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getItemFromLocalStorage } from "../helper/helper";
import routes from "../helper/routes"; // Import your routes

const Layout = ({ children }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const user = getItemFromLocalStorage("USER");
    const token = getItemFromLocalStorage("TOKEN");

    const isPrivateRoute = routes.some(
      (route) => route.layout === "PRIVATE" && route.path === location.pathname
    );
    if (user && token) {
      setUser(JSON.parse(getItemFromLocalStorage("USER")));
    }
    if (isPrivateRoute && (!user || !token)) {
      // If trying to access a private route but user is not logged in
      localStorage.removeItem("TOKEN");
      localStorage.removeItem("USER");
      navigate("/login");
    } else if (!isPrivateRoute && user && token) {
      // If user is logged in and tries to access a public route, redirect to dashboard
      navigate("/");
    }

    // Disable right-click globally
    const handleContextMenu = (event) => {
      event.preventDefault();
    };
    window.addEventListener("contextmenu", handleContextMenu);

    // Clean up on unmount
    return () => {
      window.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [navigate, location]);

  // Define the additional props you want to pass to children
  const childProps = {
    user,
    // Add any other props you want to pass here
  };

  // Use React.Children.map to pass props to children
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, childProps);
    }
    return child;
  });
  return <main id="main">{childrenWithProps}</main>;
};

export default Layout;
